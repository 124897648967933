import { actions } from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  // isFetchingList: false,
  // isFetchingSingle: false,
  // dataSourcePaging: {},
  isCreateOrUpdateModalOpen: false,
  isCreateOrUpdatePhuLucModalOpen: false,
  isPhuLucDetailModalOpen: false,
  isPhuLucUpdateModalOpen: false,
  // isSaving: false,
  editedItem: {},
  phulucItem: {},
  // isDeleting: false,
  deletedItem: {},
  isDeleteConfirmationModalOpen: false,
  // responseResult: {},
  // detailItem: {},
  isDetailModalOpen: false,
  isFetchingList: false,
  dataSourcePaging: {
    currentPage: 1,
    items: [],
    totalCount: 0,
    totalPages: 0
  },
  isFetchingListDieuChinh: false,
  dataSourcePagingDieuChinh: {
    currentPage: 1,
    items: [],
    totalCount: 0,
    totalPages: 0
  },
  responseResult: {},
  yKienPheDuyet: [],
  yKienPheDuyetGanNhat: {},
  tongHopDeXuat1121: '',
  tongHopDeXuat1122: '',
  tongHopDeXuat1124: '',
  historyData: [],
  isHistoryModalOpen: false,
  historyDetail: [],
  isHistoryDetailModalOpen: false,
  isFetchingSingle: false,
  detailItem: {},
  isSaving: false,
  isSaving2: false,
  isDeleting: false,
  isApproving: false,
  isGetting: false
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
