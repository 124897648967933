import * as types from './actionTypes'

export default {
  [types.GET_LIST_DEXUATCTXTDT_PAGING]: state => {
    state.isFetchingList = true
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_DEXUATCTXTDT_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingList = false
    state.dataSourcePaging = Object.freeze(data)
  },
  [types.GET_LIST_DEXUATCTXTDT_PAGING_FAILED]: (state, err) => {
    state.isFetchingList = false
    state.responseResult = err
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING]: state => {
    state.isFetchingListDieuChinh = true
    state.dataSourcePagingDieuChinh = {}
  },
  [types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingListDieuChinh = false
    state.dataSourcePagingDieuChinh = Object.freeze(data)
  },
  [types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_FAILED]: (state, err) => {
    state.isFetchingListDieuChinh = false
    state.responseResult = err
    state.dataSourcePagingDieuChinh = {}
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.editedItem = Object.assign({}, item)
    state.isCreateOrUpdateModalOpen = show
  },
  [types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.phulucItem = Object.assign({}, item)
    state.isCreateOrUpdatePhuLucModalOpen = show
  },
  [types.CREATE_DEXUATCTXTDT]: state => {
    state.isSaving = true
    state.editedItem = {}
  },
  [types.CREATE_DEXUATCTXTDT_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_DEXUATCTXTDT_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_CTXTDTPHULUCCUABOUBNDTINH]: state => {
    state.isSaving = true
    state.phuLucItem = {}
  },
  [types.CREATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_DEXUATCTXTDT]: state => {
    state.isSaving = true
  },
  [types.UPDATE_DEXUATCTXTDT_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.UPDATE_DEXUATCTXTDT_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH]: state => {
    state.isSaving = true
  },
  [types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.DIEUCHINH_DEXUATCTXTDT]: state => {
    state.isSaving2 = true
  },
  [types.DIEUCHINH_DEXUATCTXTDT_SUCCESS]: (state, response) => {
    state.isSaving2 = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.DIEUCHINH_DEXUATCTXTDT_FAILED]: (state, response) => {
    state.isSaving2 = false
    state.responseResult = response
  },
  [types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH]: state => {
    state.isSaving2 = true
  },
  [types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS]: (state, response) => {
    state.isSaving2 = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_FAILED]: (state, response) => {
    state.isSaving2 = false
    state.responseResult = response
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.deletedItem = Object.assign({}, item)
    state.isDeleteConfirmationModalOpen = show
  },
  [types.DELETE_DEXUATCTXTDT]: state => {
    state.isDeleting = true
  },
  [types.DELETE_DEXUATCTXTDT_SUCCESS]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
    state.deletedItem = {}
  },
  [types.DELETE_DEXUATCTXTDT_FAILED]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.TOGGLE_DETAIL_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.phulucItem = Object.assign({}, item)
    state.isPhuLucDetailModalOpen = show
  },
  [types.TOGGLE_UPDATE_PHULUC_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.phulucItem = Object.assign({}, item)
    state.isPhuLucUpdateModalOpen = show
  },
  [types.GET_DEXUATCTXTDT]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.GET_DEXUATCTXTDT_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.GET_DEXUATCTXTDT_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.APPROVE_DEXUATCTXTDT]: state => {
    state.isSaving = true
  },
  [types.APPROVE_DEXUATCTXTDT_SUCCESS]: (state, response) => {
    state.isProving = false
    state.responseResult = response
  },
  [types.APPROVE_DEXUATCTXTDT_FAILED]: (state, response) => {
    state.isProving = false
    state.responseResult = response
  },
  [types.GET_YKIENPHEDUYET]: state => {
    state.isGetting = true
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYET_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = response.obj
  },
  [types.GET_YKIENPHEDUYET_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: state => {
    state.isGetting = true
    state.yKienPheDuyetGanNhat = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = response.obj
  },
  [types.GET_YKIENPHEDUYETGANNHAT_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = {}
  },
  [types.GET_TONGHOPDEXUAT1121]: state => {
    state.tongHopDeXuat1121 = {}
  },
  [types.GET_TONGHOPDEXUAT1121_SUCCESS]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1121 = response.obj
  },
  [types.GET_TONGHOPDEXUAT1121_FAILED]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1121 = {}
  },
  [types.GET_TONGHOPDEXUAT1122]: state => {
    state.tongHopDeXuat1122 = {}
  },
  [types.GET_TONGHOPDEXUAT1122_SUCCESS]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1122 = response.obj
  },
  [types.GET_TONGHOPDEXUAT1122_FAILED]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1122 = {}
  },
  [types.GET_TONGHOPDEXUAT1124]: state => {
    state.tongHopDeXuat1124 = {}
  },
  [types.GET_TONGHOPDEXUAT1124_SUCCESS]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1124 = response.obj
  },
  [types.GET_TONGHOPDEXUAT1124_FAILED]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1124 = {}
  },
  [types.GET_HISTORY]: state => {
    state.historyData = {}
  },
  [types.GET_HISTORY_SUCCESS]: (state, response) => {
    state.historyData = response.obj
  },
  [types.GET_HISTORY_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyData = {}
  },
  [types.TOGGLE_HISTORY_MODAL]: (state, {
    show
  }) => {
    state.isHistoryModalOpen = show
  },
  [types.GET_HISTORYDETAIL]: state => {
    state.historyDetail = {}
  },
  [types.GET_HISTORYDETAIL_SUCCESS]: (state, response) => {
    state.historyDetail = response.obj
  },
  [types.GET_HISTORYDETAIL_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyDetail = {}
  },
  [types.TOGGLE_HISTORYDETAIL_MODAL]: (state, {
    show
  }) => {
    state.isHistoryDetailModalOpen = show
  }
}
