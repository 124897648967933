<template>
  <div :id="id">
    <div :class="modalClasses" tabindex="-1" role="dialog">
      <div :class="dialogClasses" role="document">
        <div class="modal-content" style="width: 200%">
          <slot name="header-wrapper" v-if="visible">
            <header class="modal-header">
              <slot name="header">
                <h5 class="modal-title">
                  {{ title }}
                </h5>
                <CButtonClose @click="hide($event)"/>
              </slot>
            </header>
          </slot>
          <slot name="body-wrapper" v-if="visible">
            <div class="modal-body">
              <slot></slot>
            </div>
          </slot>
          <slot name="footer-wrapper" v-if="visible">
            <footer class="modal-footer">
              <slot name="footer">
                <button type="button" class="btn btn-secondary" @click="hide($event)">Cancel</button>
                <button type="button" :class="btnClasses" @click="hide($event, true)">OK</button>
              </slot>
            </footer>
          </slot>
        </div>
      </div>
    </div>
    <div v-if="visible || isTransitioning" :class="backdropClasses">
    </div>
  </div>
</template>

<script>
import { v4 as uuid4 } from 'uuid'

export default {
  name: 'BaseModal',
  props: {
    show: Boolean,
    centered: Boolean,
    scrollable: Boolean,
    title: String,
    size: {
      type: String,
      validator: val => ['', 'sm', 'lg', 'xl'].includes(val)
    },
    color: String,
    fade: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      id: uuid4(),
      visible: this.show,
      isTransitioning: false,
      timeout: null
    }
  },
  computed: {
    backdropClasses () {
      return {
        'modal-backdrop': true,
        fade: this.fade,
        show: this.visible || !this.fade
      }
    },
    modalClasses () {
      return [
        'modal overflow-auto',
        {
          fade: this.fade,
          show: this.visible,
          'd-block': this.visible || this.isTransitioning,
          [`modal-${this.color}`]: this.color
        }
      ]
    },
    dialogClasses () {
      return [
        'modal-dialog',
        {
          'modal-dialog-centered': this.centered,
          'modal-dialog-scrollable': this.scrollable,
          [`modal-${this.size}`]: this.size
        }
      ]
    },
    btnClasses () {
      return [`btn btn-${this.color || 'primary'}`]
    }
  },
  watch: {
    show (val) {
      this.toggle(val)
    }
  },
  methods: {
    hide (e, accept = false) {
      this.$emit('update:show', false, e, accept)
      if (this.visible) {
        window.removeEventListener('keydown', this.hideEsc)
      }
    },
    hideEsc (event) {
      if (event.keyCode === 27) {
        this.hide(event)
      }
    },
    toggle (newVal) {
      setTimeout(() => {
        this.visible = newVal
      }, 0)
      if (newVal) {
        window.addEventListener('keydown', this.hideEsc)
      }
      if (this.fade) {
        this.isTransitioning = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.isTransitioning = false
        }, 150)
      }
    }
  },
  mounted: function () {
    if (this.show) {
      window.addEventListener('keydown', this.hideEsc)
    }
  }
}
</script>
