export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  editedItem: state => state.editedItem,
  responseResult: state => state.responseResult,
  formTitle: state => state.editedItem.id ? 'Sửa loại hoạt động' : 'Thêm loại hoạt động',
  isSaving: state => state.isSaving,
  isCreateOrUpdateModalOpen: state => state.isCreateOrUpdateModalOpen,
  isDeleting: state => state.isDeleting,
  deletedItem: state => state.deletedItem,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  detailItem: state => state.detailItem,
  isDetailModalOpen: state => state.isDetailModalOpen
}
