<template>
  <BaseModal size="xl" color="primary" :show="show" @update:show="toggleModal">
    <template #header>
      <h5 class="modal-title">
        <!-- <CIcon name="cil-pencil" :width="25"/> -->
        {{ title }}
      </h5>
      <CButtonClose @click="toggleModal(false)"/>
    </template>
    <template #default>
      <p>Bạn có chắc chắn muốn phê duyệt?</p>

      <CRow>
        <!-- <CCol lg="300"> -->
          <div class="form-group">
            <CTextarea label="Ý kiến phê duyệt" horizontal :value.sync="items.noiDung"/>
          </div>
        <!-- </CCol> -->
      </CRow>

      <div class="form-group row mb-0 mt-3">
        <div class="col-12">
          <b class="mt-5">Đính kèm file</b>
          <FileUpload ref="upload" @onChange="changeFiles" :type="type"/>
        </div>
      </div>

    </template>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="toggleModal(false)">Hủy bỏ</button>
      <button type="button" class="btn btn-danger" @click="confirmApprove" :disabled="isApproving">
        <span v-if="isApproving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Xác nhận
      </button>
    </template>
  </BaseModal>
</template>

<script>

import FileUpload from '@/components/file-upload/FileUpload'

export default {
  name: 'ApproveConfirmationModal',
  components: {
    FileUpload
  },
  data () {
    return {
      items: {
        noiDung: '',
        dinhKem: ''
      },
      Attachments: ''
    }
  },
  props: {
    title: String,
    show: Boolean,
    isApproving: Boolean,
    item: Object,
    type: String
  },
  methods: {
    toggleModal (value) {
      this.$emit('update:show', value)
    },
    confirmApprove () {
      const data = {
        id: this.item.id,
        noiDung: this.items.noiDung,
        dinhKem: JSON.stringify(this.Attachments)
      }

      this.$emit('data', data)

      this.$emit('confirm-approve', true)
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    }
  }
}
</script>

<style scoped>
.form-group {
  width: 100%;
  margin-left: 10px;
  margin-right: 40px;
}
</style>
