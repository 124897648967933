<template>
  <div>
    <slot name="over-table"/>
    <div class="position-relative table-responsive">
      <table class="table table-striped table-fixed table-hover table-bordered">
        <thead>
        <slot name="thead-top"/>
        <tr>
          <template v-for="(name, index) in columnNames">
            <th @click="changeSort(rawColumnNames[index], index)" :class="[headerClass(index), sortingIconStyles]"
                :style="headerStyles(index)" :key="index">
              <slot :name="`${rawColumnNames[index]}-header`">
                <div>{{ name }}</div>
              </slot>
              <slot v-if="isSortable(index)" name="sorting-icon" :state="getIconState(index)"
                    :classes="iconClasses(index)">
                <CIcon width="18" name="cil-arrow-top" :class="iconClasses(index)"
                       :aria-label="`change column: '${name}' sorting`"/>
              </slot>
            </th>
          </template>
        </tr>
        </thead>

        <tbody class="position-relative">
        <template v-for="(item, itemIndex) in passedItems">
          <tr :class="item._classes" :key="itemIndex">
            <template v-for="(colName, index) in rawColumnNames">
              <template v-if="$scopedSlots[colName]">
                <slot :name="colName" :item="item" :index="itemIndex + firstItemIndex"/>
              </template>
              <td v-else :class="cellClass(item, colName, index)" :key="index">{{ String(item[colName]) }}</td>
            </template>
          </tr>
        </template>
        <tr v-if="!passedItems.length && !loading">
          <td :colspan="colspan">
            <slot name="no-items-view">
              <div class="text-center my-5">
                <h2>Không có dữ liệu
                  <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                </h2>
              </div>
            </slot>
          </td>
        </tr>
        </tbody>
      </table>

      <slot name="loading" v-if="loading">
        <CElementCover :boundaries="[{ sides: ['top'], query: 'td' },{ sides: ['bottom'], query: 'tbody' }]"/>
      </slot>
    </div>

    <slot name="under-table"/>

    <CPagination v-if="pagination" v-show="totalPages > 1" :activePage.sync="page" :pages="totalPages"
                 v-bind="typeof pagination === 'object' ? pagination : null"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    items: Array,
    fields: {
      type: Array,
      required: true
    },
    totalPages: Number,
    itemsPerPage: {
      type: Number,
      default: 15
    },
    activePage: Number,
    pagination: [Boolean, Object],
    sorter: [Boolean, Object],
    loading: Boolean
  },
  data () {
    return {
      passedItems: this.items || [],
      sorterState: {
        column: null,
        asc: true
      },
      page: this.activePage || 1
    }
  },
  watch: {
    computedPage (val) {
      this.$emit('page-change', val)
    },
    items (val, oldVal) {
      if (val && oldVal && this.objectsAreIdentical(val, oldVal)) {
        return
      }
      this.passedItems = val || []
    }
  },
  computed: {
    firstItemIndex () {
      return (this.computedPage - 1) * this.itemsPerPage || 0
    },
    computedPage () {
      return this.pagination ? this.page : this.activePage
    },
    rawColumnNames () {
      return this.fields.map(el => el.key)
    },
    columnNames () {
      return this.fields.map(el => el.label)
    },
    sortingIconStyles () {
      return { 'position-relative pr-4': this.sorter }
    },
    colspan () {
      return this.rawColumnNames.length
    },
    objectsAreIdentical (obj1, obj2) {
      return obj1.length === obj2.length &&
        JSON.stringify(obj1) === JSON.stringify(obj2)
    }
  },
  methods: {
    changeSort (column, index) {
      if (!this.isSortable(index)) {
        return
      }
      // if column changed or sort was descending change asc to true
      const state = this.sorterState
      const columnRepeated = state.column === column
      if (!this.sorter || !this.sorter.resetable) {
        state.column = column
      } else {
        state.column = columnRepeated && state.asc === false ? null : column
      }
      state.asc = !(columnRepeated && state.asc)
      this.$emit('update:sorter-value', state)
    },
    cellClass (item, colName, index) {
      const classes = []
      if (item._cellClasses && item._cellClasses[colName]) {
        classes.push(item._cellClasses[colName])
      }
      if (this.fields && this.fields[index]._classes) {
        classes.push(this.fields[index]._classes)
      }
      return classes
    },
    isSortable (index) {
      return this.sorter && this.fields[index].sorter !== false
    },
    headerClass (index) {
      const fields = this.fields
      return fields && fields[index]._classes ? fields[index]._classes : ''
    },
    headerStyles (index) {
      let style = 'vertical-align:middle;overflow:hidden;'
      if (this.isSortable(index)) {
        style += 'cursor:pointer;'
      }
      if (this.fields && this.fields[index] && this.fields[index]._style) {
        style += this.fields[index]._style
      }
      return style
    },
    getIconState (index) {
      const direction = this.sorterState.asc ? 'asc' : 'desc'
      return this.rawColumnNames[index] === this.sorterState.column ? direction : 0
    },
    iconClasses (index) {
      const state = this.getIconState(index)
      return [
        'icon-transition position-absolute arrow-position',
        {
          transparent: !state,
          'rotate-icon': state === 'desc'
        }
      ]
    }
  }
}
</script>

<style scoped>
thead tr:not(:last-child) th {
  border-bottom: 1px;
}

.transparent {
  opacity: 0.4;
}

.icon-transition {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.arrow-position {
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rotate-icon {
  -ms-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
</style>
