export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  responseResult: state => state.responseResult,
  isFetchingSingle: state => state.isFetchingSingle,
  detailItem: state => state.detailItem,
  yKienPheDuyet: state => state.yKienPheDuyet,
  yKienPheDuyetGanNhat: state => state.yKienPheDuyetGanNhat,
  isSaving: state => state.isSaving,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  isDeleting: state => state.isDeleting,
  isApproving: state => state.isApproving,
  isGetting: state => state.isGetting
}
