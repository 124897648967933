<template>
  <BaseFormGroup
    v-bind="{
      append, prepend, validFeedback, invalidFeedback,
      tooltipFeedback, description, wrapperClasses, class: computedClasses
    }"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="id" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>

    <template #input>
      <select
        v-bind="$attrs"
        v-on="$listeners"
        :id="id"
        :class="inputClasses"
        @change="onSelect($event)"
      >
        <option
          v-if="placeholder"
          value=""
          selected
          hidden
        >
          {{ placeholder }}
        </option>
        <template v-for="(option, key) in options">
          <option
            v-if="typeof option === 'object'"
            :value="option.value"
            v-bind="option.attrs"
            :disabled="option.disabled"
            :selected="option.value === value"
            :data-key="key"
            :key="key"
          >
            {{ option.label || option.value }}
          </option>
          <option
            v-else
            :value="option"
            :selected="option === value"
            :data-key="key"
            :key="key"
          >
            {{ option }}
          </option>
        </template>
      </select>
    </template>

    <template
      v-for="slot in $options.slots"
      #[slot]
    >
      <slot :name="slot"></slot>
    </template>
  </BaseFormGroup>
</template>

<script>
import { v4 as uuid4 } from 'uuid'

export default {
  name: 'BaseSelect',
  inheritAttrs: false,
  props: {
    validFeedback: String,
    invalidFeedback: Array,
    tooltipFeedback: Boolean,
    description: String,
    append: String,
    prepend: String,
    label: String,
    wasValidated: Boolean,
    touched: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: [Boolean, Function],
      default: null
    },
    addInputClasses: [String, Array, Object],
    addLabelClasses: [String, Array, Object],
    horizontal: [Boolean, Object],
    size: {
      type: String,
      validator: str => ['', 'sm', 'lg'].includes(str)
    },
    addWrapperClasses: [String, Array, Object],
    options: Array,
    value: [String, Number, Boolean, Array],
    plaintext: Boolean,
    placeholder: String,
    custom: Boolean
  },
  data () {
    return {
      id: uuid4(),
      state: this.value || undefined
    }
  },
  watch: {
    value (val) {
      this.state = val
    }
  },
  computed: {
    haveCustomSize () {
      return ['sm', 'lg'].includes(this.size)
    },
    computedClasses () {
      return [
        'form-group',
        {
          'was-validated': this.wasValidated,
          'form-row': this.isHorizontal
        }
      ]
    },
    labelClasses () {
      return [
        this.addLabelClasses,
        {
          'col-form-label': this.isHorizontal,
          [this.horizontal.label || 'col-sm-3']: this.isHorizontal,
          [`col-form-label-${this.size}`]: this.haveCustomSize
        }
      ]
    },
    customSizeClass () {
      if (this.haveCustomSize && !this.haveWrapper) {
        return `${this.custom ? 'custom-select' : 'form-control'}-${this.size}`
      }
      return ''
    },
    inputClass () {
      const standardClass = `form-control${this.plaintext ? '-plaintext' : ''}`
      return this.custom ? 'custom-select' : standardClass
    },
    inputClasses () {
      return [
        this.inputClass || `form-control${this.plaintext ? '-plaintext' : ''}`,
        this.validationClass,
        this.addInputClasses,
        this.customSizeClass
      ]
    },
    computedIsValid () {
      if (typeof this.isValid === 'function') {
        return this.isValid(this.state)
      }
      return this.isValid
    },
    validationClass () {
      if (typeof this.computedIsValid === 'boolean') {
        if (this.computedIsValid) {
          return 'is-valid'
        }
        if (!this.computedIsValid && this.touched) {
          return 'is-invalid'
        }
      }
      return ''
    },
    isHorizontal () {
      return Boolean(this.horizontal)
    },
    haveInputGroup () {
      return Boolean(
        this.tooltipFeedback ||
        this.append ||
        this.prepend ||
        this.$slots.append ||
        this.$slots.prepend ||
        this.$slots['append-content'] ||
        this.$slots['prepend-content']
      )
    },
    haveWrapper () {
      return this.haveInputGroup || Boolean(this.addWrapperClasses || this.isHorizontal)
    },
    wrapperClasses () {
      if (this.haveWrapper) {
        return [
          this.addWrapperClasses,
          {
            [this.horizontal.input || 'col-sm-9']: this.isHorizontal,
            'input-group': this.haveInputGroup,
            [`input-group-${this.size}`]: this.haveCustomSize
          }
        ]
      }
      return ''
    }
  },
  methods: {
    onSelect (e) {
      if (this.$attrs.multiple !== undefined) return
      const optionIndex = e.target.selectedOptions[0].dataset.key
      const option = this.options[optionIndex]
      const value = option.value !== undefined ? option.value : option
      this.state = value
      this.$emit('update:value', value, e)
    }
  }
}
</script>

<style scoped>

</style>
