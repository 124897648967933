<template>
  <CSidebar fixed :minimize="minimize" :show="show"
            @update:show="(value) => $store.commit('ui/set', ['sidebarShow', value])">
    <CSidebarBrand class="c-sidebar-brand" to="/">
      <img src="@/assets/images/Logo_FIA_2014.jpg" alt="FIA Logo full" class="c-sidebar-brand-full" height="43">
      <span class=" c-sidebar-brand-full text-uppercase ml-1" style="color: white">
        Bộ kế hoạch và đầu tư <br>
        Cục đầu tư nước ngoài <br>
      </span>
      <img src="@/assets/images/Logo_FIA_2014.jpg" alt="FIA mini" class="c-sidebar-brand-minimized" height="34">
    </CSidebarBrand>
    <CSidebarNav>
      <CSidebarNavItem name="Dashboard" icon="cil-speedometer" to="/dashboard"></CSidebarNavItem>
      <CSidebarNavDropdown name="CT XTĐT của Bộ, Ngành" route="/de-xuat-ct-xtdt" icon="cil-star"
                           :items="deXuatCTXTDTBoNganhItems"
                           v-if="deXuatCTXTDTBoNganhItems && deXuatCTXTDTBoNganhItems.length > 0"/>
      <CSidebarNavDropdown name="CT XTĐT của Địa phương" route="/de-xuat-ct-xtdt-dia-phuong" icon="cil-star"
                           :items="deXuatCTXTDTDiaPhuongItems"
                           v-if="deXuatCTXTDTDiaPhuongItems && deXuatCTXTDTDiaPhuongItems.length > 0"/>
      <CSidebarNavDropdown name="CT XTĐT Quốc gia" route="/de-xuat-ct-xtdt-qg" icon="cil-star"
                           :items="deXuatCTXTDTQuocGiaItems"
                           v-if="deXuatCTXTDTQuocGiaItems && deXuatCTXTDTQuocGiaItems.length > 0"/>
      <CSidebarNavTitle
        v-if="viewVanBanTBKHTCHDXTHHDTTMDLNGKT || viewVanBanTBTCHDXTDTKSDNSNN || viewVanBanPAXTDTTHDDNCCNC">Các hoạt
        động xúc tiến đầu tư khác
      </CSidebarNavTitle>
      <CSidebarNavItem name="Thông báo kế hoạch XT hỗn hợp" to="/thong-bao-ke-hoach-xt-hon-hop" icon="cil-calculator"
                       v-if="viewVanBanTBKHTCHDXTHHDTTMDLNGKT"/>
      <CSidebarNavItem name="Thông báo tổ chức hoạt động XTĐT không sử dụng ngân sách nhà nước"
                       to="/thong-bao-to-chuc-hd-xtdt" icon="cil-calculator" v-if="viewVanBanTBTCHDXTDTKSDNSNN"/>
      <CSidebarNavItem name="Phương án XTĐT trong hoạt động đối ngoại cấp cao"
                       to="/phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao" icon="cil-calculator"
                       v-if="viewVanBanPAXTDTTHDDNCCNC"/>
      <CSidebarNavTitle v-if="viewNhomQuyenItem || viewNguoiDungItem || danhMucItems.length > 0">Quản trị hệ thống
      </CSidebarNavTitle>
      <CSidebarNavItem name="Người dùng" to="/nguoi-dung" icon="cil-user" v-if="viewNguoiDungItem"/>
      <CSidebarNavItem name="Nhóm quyền" to="/nhom-quyen" icon="cil-group" v-if="viewNhomQuyenItem"/>
      <CSidebarNavDropdown name="Danh mục" route="/danh-muc" icon="cil-puzzle" :items="danhMucItems"
                           v-if="danhMucItems.length > 0"/>
    </CSidebarNav>
    <CSidebarMinimizer class="c-class-toggler"
                       @click.native="$store.commit('ui/set', ['sidebarMinimize', !minimize])"/>
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppSidebar',
  computed: {
    show () {
      return this.$store.state.ui.sidebarShow
    },
    minimize () {
      return this.$store.state.ui.sidebarMinimize
    },
    ...mapGetters('auth', {
      danhMucItems: 'danhMucItems',
      viewNguoiDungItem: 'viewNguoiDungItem',
      viewNhomQuyenItem: 'viewNhomQuyenItem',
      viewVanBanTBKHTCHDXTHHDTTMDLNGKT: 'viewVanBanTBKHTCHDXTHHDTTMDLNGKT',
      viewVanBanTBTCHDXTDTKSDNSNN: 'viewVanBanTBTCHDXTDTKSDNSNN',
      viewVanBanPAXTDTTHDDNCCNC: 'viewVanBanPAXTDTTHDDNCCNC',
      deXuatCTXTDTBoNganhItems: 'deXuatCTXTDTBoNganhItems',
      deXuatCTXTDTDiaPhuongItems: 'deXuatCTXTDTDiaPhuongItems',
      deXuatCTXTDTQuocGiaItems: 'deXuatCTXTDTQuocGiaItems'
    })
  }
}
</script>

<style scoped>

</style>
