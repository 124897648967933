<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        Xin chào {{ fullName }}
<!--        <div class="c-avatar">-->
<!--          <img :src="avatar" class="c-avatar-img "/>-->
<!--        </div>-->
      </CHeaderNavLink>
    </template>
<!--    <CDropdownHeader tag="div" class="text-center" color="light">-->
<!--      <strong>Account</strong>-->
<!--    </CDropdownHeader>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-bell"/>-->
<!--      Updates-->
<!--      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-envelope-open"/>-->
<!--      Messages-->
<!--      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-task"/>-->
<!--      Tasks-->
<!--      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-comment-square"/>-->
<!--      Comments-->
<!--      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownHeader tag="div" class="text-center" color="light">-->
<!--      <strong>Settings</strong>-->
<!--    </CDropdownHeader>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-user"/>-->
<!--      Profile-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-settings"/>-->
<!--      Settings-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-dollar"/>-->
<!--      Payments-->
<!--      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-file"/>-->
<!--      Projects-->
<!--      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownDivider/>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-shield-alt"/>-->
<!--      Lock Account-->
<!--    </CDropdownItem>-->
    <CDropdownItem @click="changPassword">
      <CIcon name="cil-pencil"/>
      Đổi mật khẩu
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked"/>
      Đăng xuất
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/2.jpg'

export default {
  name: 'AppHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42,
      avatar,
      fullName: ''
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    changPassword () {
      this.$router.push('/changpassword')
    },
    getUserInfo () {
      const dataUser = localStorage.getItem('authData')
      // console.log(dataUser)
      this.fullName = JSON.parse(dataUser).fullName
    }
  },
  mounted () {
    this.getUserInfo()
  }
}
</script>

<style scoped>
</style>
