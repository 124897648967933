import * as types from './actionTypes'
import jwtInterceptor from '@/shared/jwtInterceptor'
import appConfig from '@/shared/appConfig'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/DeXuatCTXTDTQuocGia`

export const actions = {
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/Filter`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/FilterPheDuyet`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_UPDATE_MODAL, payload)
  },
  [types.TOGGLE_CREATE_PHULUC_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_PHULUC_MODAL, payload)
  },
  [types.TOGGLE_CREATE_DEAN_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_DEAN_MODAL, payload)
  },
  [types.TOGGLE_DETAIL_DEAN_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_DETAIL_DEAN_MODAL, payload)
  },
  [types.TOGGLE_UPDATE_DEAN_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_UPDATE_DEAN_MODAL, payload)
  },
  [types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL, payload)
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (context, value) => {
    context.commit(types.TOGGLE_DELETE_CONFIRMATION_MODAL, value)
  },
  [types.CREATE_DEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.CREATE_DEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/`, payload).catch(error => {
      context.commit(types.CREATE_DEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_DEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.CREATE_DEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.CREATE_CTXTDTQGPHULUC]: async (context, payload) => {
    context.commit(types.CREATE_CTXTDTQGPHULUC)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.deXuatCTXTDTQuocGiaGuid}/chitiet`, payload).catch(error => {
      context.commit(types.CREATE_CTXTDTQGPHULUC_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_CTXTDTQGPHULUC_FAILED, response.data)
      } else {
        context.commit(types.CREATE_CTXTDTQGPHULUC_SUCCESS, response.data)
      }
    }
  },
  [types.CREATE_DEANDEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.CREATE_DEANDEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.deXuatCTXTDTQuocGiaGuid}/chitiet/${payload.chiTietDeXuatCTXTDTQuocGiaGuid}/deanhoatdong`, payload).catch(error => {
      context.commit(types.CREATE_DEANDEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_DEANDEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.CREATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_DEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.UPDATE_DEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.deXuatCTXTDTQuocGiaId}`, payload).catch(error => {
      context.commit(types.UPDATE_DEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_DEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_DEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_CTXTDTQGPHULUC]: async (context, payload) => {
    context.commit(types.UPDATE_CTXTDTQGPHULUC)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.deXuatCTXTDTQuocGiaId}/chitiet`, payload).catch(error => {
      context.commit(types.UPDATE_CTXTDTQGPHULUC_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_CTXTDTQGPHULUC_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_CTXTDTQGPHULUC_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_DEANDEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.UPDATE_DEANDEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}/chitiet/${payload.chiTietDeXuatCTXTDTQuocGiaId}/deanhoatdong/${payload.deanid}`, payload).catch(error => {
      context.commit(types.UPDATE_DEANDEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_DEANDEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.DIEUCHINH_DEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.DIEUCHINH_DEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.deXuatCTXTDTQuocGiaId}/dieuchinhdexuat`, payload).catch(error => {
      context.commit(types.DIEUCHINH_DEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DIEUCHINH_DEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.DIEUCHINH_DEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA)
    // console.log(payload)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}/dieuchinhdexuat/chitiet/${payload.chiTietDeXuatCTXTDTQuocGiaId}/deanhoatdong`, payload).catch(error => {
      context.commit(types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.DELETE_DEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.DELETE_DEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.delete(`${BASE_ENDPOINT}/${payload.id}`).catch(error => {
      context.commit(types.DELETE_DEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DELETE_DEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.DELETE_DEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.GET_DEXUATCTXTDTQUOCGIA]: async (context, id) => {
    context.commit(types.GET_DEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_DEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
    } else {
      context.commit(types.GET_DEXUATCTXTDTQUOCGIA_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_DETAIL_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_DETAIL_MODAL, payload)
  },
  [types.TOGGLE_UPDATE_PHULUC_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_UPDATE_PHULUC_MODAL, payload)
  },
  [types.APPROVE_DEXUATCTXTDTQUOCGIA]: async (context, payload) => {
    context.commit(types.APPROVE_DEXUATCTXTDTQUOCGIA)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.dexuatguid}/PheDuyet`, payload).catch(error => {
      context.commit(types.APPROVE_DEXUATCTXTDTQUOCGIA_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.APPROVE_DEXUATCTXTDTQUOCGIA_FAILED, response.data)
      } else {
        context.commit(types.APPROVE_DEXUATCTXTDTQUOCGIA_SUCCESS, response.data)
      }
    }
  },
  [types.GET_YKIENPHEDUYET]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYET)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyet`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYET_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYET_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYETGANNHAT)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyetgannhat`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_TONGHOPDEXUAT1111]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1111)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1111`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1111_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1111_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1111_SUCCESS, response.data)
      }
    }
  },
  [types.GET_TONGHOPDEXUAT1112]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1112)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1112`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1112_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1112_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1112_SUCCESS, response.data)
      }
    }
  },
  [types.GET_TONGHOPDEXUAT1114]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1114)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1114`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1114_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1114_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1114_SUCCESS, response.data)
      }
    }
  },
  [types.GET_HISTORY]: async (context, id) => {
    context.commit(types.GET_HISTORY)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORY_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORY_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_HISTORYDETAIL]: async (context, id) => {
    context.commit(types.GET_HISTORYDETAIL)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/chitiet/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORYDETAIL_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORYDETAIL_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_HISTORYDEAN]: async (context, id) => {
    context.commit(types.GET_HISTORYDEAN)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/deanhoatdong/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORYDEAN_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORYDEAN_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_HISTORYHANGMUCCONGVIEC]: async (context, payload) => {
    context.commit(types.GET_HISTORYHANGMUCCONGVIEC)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${payload.id}/chitiet/${payload.chitietid}/hangmuccongviec/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORYHANGMUCCONGVIEC_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORYHANGMUCCONGVIEC_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_HISTORYDUTOANKINHPHI]: async (context, payload) => {
    context.commit(types.GET_HISTORYDUTOANKINHPHI)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${payload.id}/chitiet/${payload.chitietid}/dutoankinhphi/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORYDUTOANKINHPHI_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORYDUTOANKINHPHI_FAILED, response.data.message || response.statusText)
    }
  }
}
