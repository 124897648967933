<template>
  <BaseModal size="lg" color="primary" :show="show" @update:show="toggleModal">
    <template #header>
      <h5 class="modal-title">
        <!-- <CIcon name="cil-pencil" :width="25"/> -->
        {{ title }}
      </h5>
      <CButtonClose @click="toggleModal(false)"/>
    </template>
    <template #default>
      <p>Bạn có chắc chắn muốn phê duyệt?</p>
      <CRow>
        <div class="form-group row">
          <div class="col-3">
            Loại phê duyệt
          </div>
          <div class="col-9">
            <select horizontal v-model="items.loaiPheDuyet" class="form-control" >
              <option v-for="it in LoaiPheDuyet" :value="it.value" v-bind:key="it.value">{{ it.label }}</option>
            </select>
          </div>
        </div>
      </CRow>
      <CRow>
        <!-- <CCol lg="300"> -->
          <div class="form-group">
            <CTextarea label="Ý kiến phê duyệt" horizontal :value.sync="items.noiDung"/>
          </div>
        <!-- </CCol> -->
      </CRow>

      <div class="form-group row mb-0 mt-3">
        <div class="col-12">
          <b class="mt-5">Đính kèm file</b>
          <FileUpload ref="upload" @onChange="changeFiles" :type="type"/>
        </div>
      </div>

    </template>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="toggleModal(false)">Hủy bỏ</button>
      <button type="button" class="btn btn-danger" @click="confirmApprove" :disabled="isApproving">
        <span v-if="isApproving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Xác nhận
      </button>
    </template>
  </BaseModal>
</template>

<script>

import FileUpload from '@/components/file-upload/FileUpload'
import { enums } from '@/shared/enums'

export default {
  name: 'ApproveConfirmationModal',
  components: {
    FileUpload
  },
  data () {
    return {
      items: {
        noiDung: '',
        dinhKem: '',
        loaiPheDuyet: 0,
        id: 0
      },
      Attachments: '',
      LoaiPheDuyet: enums.loaiPheDuyetDeXuatCTXTDT
    }
  },
  props: {
    title: String,
    show: Boolean,
    isApproving: Boolean,
    item: Object,
    type: String
  },
  methods: {
    toggleModal (value) {
      this.$emit('update:show', value)
    },
    confirmApprove () {
      const data = {
        dexuatguid: this.item.guid,
        noiDung: this.items.noiDung,
        loaiPheDuyet: this.items.loaiPheDuyet,
        dinhKem: JSON.stringify(this.Attachments),
        id: this.item.id
      }

      this.$emit('data', data)

      this.$emit('confirm-approve', true)
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    }
  }
}
</script>

<style scoped>
.form-group {
  width: 100%;
  margin-left: 10px;
  margin-right: 40px;
}
</style>
