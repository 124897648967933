const MODE = process.env.VUE_APP_MODE
function getBaseHost () {
  let baseUrl = ''

  if (MODE === 'production') {
    baseUrl = window.location.origin
  } else {
    baseUrl = process.env.VUE_APP_BASE_HOST
  }

  return baseUrl
}
const appConfig = {
  BASE_HOST: getBaseHost(),
  CLIENT_ID: 'FRONTEND',
  CLIENT_SECRET: '51F6B09B-5EF3-48D0-97B9-D4D6DAE109D8'
}

export default appConfig
