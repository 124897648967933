export const GET_LIST_VUNGKINHTE_PAGING = 'GET_LIST_VUNGKINHTE_PAGING'
export const GET_LIST_VUNGKINHTE_PAGING_SUCCESS = 'GET_LIST_VUNGKINHTE_PAGING_SUCCESS'
export const GET_LIST_VUNGKINHTE_PAGING_FAILED = 'GET_LIST_VUNGKINHTE_PAGING_FAILED'

export const GET_VUNGKINHTE = 'GET_VUNGKINHTE'
export const GET_VUNGKINHTE_SUCCESS = 'GET_VUNGKINHTE_SUCCESS'
export const GET_VUNGKINHTE_FAILED = 'GET_VUNGKINHTE_FAILED'

export const CREATE_VUNGKINHTE = 'CREATE_VUNGKINHTE'
export const CREATE_VUNGKINHTE_SUCCESS = 'CREATE_VUNGKINHTE_SUCCESS'
export const CREATE_VUNGKINHTE_FAILED = 'CREATE_VUNGKINHTE_FAILED'

export const UPDATE_VUNGKINHTE = 'UPDATE_VUNGKINHTE'
export const UPDATE_VUNGKINHTE_SUCCESS = 'UPDATE_VUNGKINHTE_SUCCESS'
export const UPDATE_VUNGKINHTE_FAILED = 'UPDATE_VUNGKINHTE_FAILED'

export const DELETE_VUNGKINHTE = 'DELETE_VUNGKINHTE'
export const DELETE_VUNGKINHTE_SUCCESS = 'DELETE_VUNGKINHTE_SUCCESS'
export const DELETE_VUNGKINHTE_FAILED = 'DELETE_VUNGKINHTE_FAILED'
