export const GET_LIST_NGANHLINHVUCKEUGOIDAUTU_PAGING = 'GET_LIST_NGANHLINHVUCKEUGOIDAUTU_PAGING'
export const GET_LIST_NGANHLINHVUCKEUGOIDAUTU_PAGING_SUCCESS = 'GET_LIST_NGANHLINHVUCKEUGOIDAUTU_PAGING_SUCCESS'
export const GET_LIST_NGANHLINHVUCKEUGOIDAUTU_PAGING_FAILED = 'GET_LIST_NGANHLINHVUCKEUGOIDAUTU_PAGING_FAILED'

export const GET_NGANHLINHVUCKEUGOIDAUTU = 'GET_NGANHLINHVUCKEUGOIDAUTU'
export const GET_NGANHLINHVUCKEUGOIDAUTU_SUCCESS = 'GET_NGANHLINHVUCKEUGOIDAUTU_SUCCESS'
export const GET_NGANHLINHVUCKEUGOIDAUTU_FAILED = 'GET_NGANHLINHVUCKEUGOIDAUTU_FAILED'

export const CREATE_NGANHLINHVUCKEUGOIDAUTU = 'CREATE_NGANHLINHVUCKEUGOIDAUTU'
export const CREATE_NGANHLINHVUCKEUGOIDAUTU_SUCCESS = 'CREATE_NGANHLINHVUCKEUGOIDAUTU_SUCCESS'
export const CREATE_NGANHLINHVUCKEUGOIDAUTU_FAILED = 'CREATE_NGANHLINHVUCKEUGOIDAUTU_FAILED'

export const UPDATE_NGANHLINHVUCKEUGOIDAUTU = 'UPDATE_NGANHLINHVUCKEUGOIDAUTU'
export const UPDATE_NGANHLINHVUCKEUGOIDAUTU_SUCCESS = 'UPDATE_NGANHLINHVUCKEUGOIDAUTU_SUCCESS'
export const UPDATE_NGANHLINHVUCKEUGOIDAUTU_FAILED = 'UPDATE_NGANHLINHVUCKEUGOIDAUTU_FAILED'

export const DELETE_NGANHLINHVUCKEUGOIDAUTU = 'DELETE_NGANHLINHVUCKEUGOIDAUTU'
export const DELETE_NGANHLINHVUCKEUGOIDAUTU_SUCCESS = 'DELETE_NGANHLINHVUCKEUGOIDAUTU_SUCCESS'
export const DELETE_NGANHLINHVUCKEUGOIDAUTU_FAILED = 'DELETE_NGANHLINHVUCKEUGOIDAUTU_FAILED'
