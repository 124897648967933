import { actions } from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  isFetchingList: false,
  dataSourcePaging: {
    currentPage: 1,
    items: [],
    totalCount: 0,
    totalPages: 0
  },
  responseResult: {},
  isFetchingSingle: false,
  detailItem: {},
  isSaving: false,
  isDeleting: false,
  isChanging: false
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
