export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  responseResult: state => state.responseResult,
  isFetchingSingle: state => state.isFetchingSingle,
  detailItem: state => state.detailItem,
  isSaving: state => state.isSaving,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  isDeleting: state => state.isDeleting,
  permissions: state => state.permissions,
  isChanging: state => state.isChanging
}
