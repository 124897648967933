export const GET_LIST_LOAIHOATDONG_PAGING = 'GET_LIST_LOAIHOATDONG_PAGING'
export const GET_LIST_LOAIHOATDONG_PAGING_SUCCESS = 'GET_LIST_LOAIHOATDONG_PAGING_SUCCESS'
export const GET_LIST_LOAIHOATDONG_PAGING_FAILED = 'GET_LIST_LOAIHOATDONG_PAGING_FAILED'

export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL'
export const GET_LOAIHOATDONG = 'GET_LOAIHOATDONG'
export const GET_LOAIHOATDONG_SUCCESS = 'GET_LOAIHOATDONG_SUCCESS'
export const GET_LOAIHOATDONG_FAILED = 'GET_LOAIHOATDONG_FAILED'

export const TOGGLE_CREATE_UPDATE_MODAL = 'TOGGLE_CREATE_UPDATE_MODAL'

export const CREATE_LOAIHOATDONG = 'CREATE_LOAIHOATDONG'
export const CREATE_LOAIHOATDONG_SUCCESS = 'CREATE_LOAIHOATDONG_SUCCESS'
export const CREATE_LOAIHOATDONG_FAILED = 'CREATE_LOAIHOATDONG_FAILED'

export const UPDATE_LOAIHOATDONG = 'UPDATE_LOAIHOATDONG'
export const UPDATE_LOAIHOATDONG_SUCCESS = 'UPDATE_LOAIHOATDONG_SUCCESS'
export const UPDATE_LOAIHOATDONG_FAILED = 'UPDATE_LOAIHOATDONG_FAILED'

export const TOGGLE_DELETE_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CONFIRMATION_MODAL'
export const DELETE_LOAIHOATDONG = 'DELETE_LOAIHOATDONG'
export const DELETE_LOAIHOATDONG_SUCCESS = 'DELETE_LOAIHOATDONG_SUCCESS'
export const DELETE_LOAIHOATDONG_FAILED = 'DELETE_LOAIHOATDONG_FAILED'
