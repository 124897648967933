export const GET_LIST_PHUONGAN_PAGING = 'GET_LIST_PHUONGAN_PAGING'
export const GET_LIST_PHUONGAN_PAGING_SUCCESS = 'GET_LIST_PHUONGAN_PAGING_SUCCESS'
export const GET_LIST_PHUONGAN_PAGING_FAILED = 'GET_LIST_PHUONGAN_PAGING_FAILED'

export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL'
export const GET_PHUONGAN = 'GET_PHUONGAN'
export const GET_PHUONGAN_SUCCESS = 'GET_PHUONGAN_SUCCESS'
export const GET_PHUONGAN_FAILED = 'GET_PHUONGAN_FAILED'

export const TOGGLE_CREATE_UPDATE_MODAL = 'TOGGLE_CREATE_UPDATE_MODAL'

export const CREATE_PHUONGAN = 'CREATE_PHUONGAN'
export const CREATE_PHUONGAN_SUCCESS = 'CREATE_PHUONGAN_SUCCESS'
export const CREATE_PHUONGAN_FAILED = 'CREATE_PHUONGAN_FAILED'

export const UPDATE_PHUONGAN = 'UPDATE_PHUONGAN'
export const UPDATE_PHUONGAN_SUCCESS = 'UPDATE_PHUONGAN_SUCCESS'
export const UPDATE_PHUONGAN_FAILED = 'UPDATE_PHUONGAN_FAILED'

export const TOGGLE_DELETE_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CONFIRMATION_MODAL'
export const DELETE_PHUONGAN = 'DELETE_PHUONGAN'
export const DELETE_PHUONGAN_SUCCESS = 'DELETE_PHUONGAN_SUCCESS'
export const DELETE_PHUONGAN_FAILED = 'DELETE_PHUONGAN_FAILED'

export const APPROVE_PHUONGAN = 'APPROVE_PHUONGAN'
export const APPROVE_PHUONGAN_SUCCESS = 'APPROVE_PHUONGAN_SUCCESS'
export const APPROVE_PHUONGAN_FAILED = 'APPROVE_PHUONGAN_FAILED'

export const GET_YKIENPHEDUYET = 'GET_YKIENPHEDUYET'
export const GET_YKIENPHEDUYET_SUCCESS = 'GET_YKIENPHEDUYET_SUCCESS'
export const GET_YKIENPHEDUYET_FAILED = 'GET_YKIENPHEDUYET_FAILED'

export const GET_YKIENPHEDUYETGANNHAT = 'GET_YKIENPHEDUYETGANNHAT'
export const GET_YKIENPHEDUYETGANNHAT_SUCCESS = 'GET_YKIENPHEDUYETGANNHAT_SUCCESS'
export const GET_YKIENPHEDUYETGANNHAT_FAILED = 'GET_YKIENPHEDUYETGANNHAT_FAILED'
