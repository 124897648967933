import * as types from './actionTypes'
import jwtInterceptor from '@/shared/jwtInterceptor'
import appConfig from '@/shared/appConfig'
// import { APPROVE_THONGBAOTOCHUC } from './actionTypes'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/VanBanTBTCHDXTDTKSDNSNN`

export const actions = {
  [types.GET_LIST_THONGBAOTOCHUC_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_THONGBAOTOCHUC_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/Filter`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_THONGBAOTOCHUC_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_THONGBAOTOCHUC_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_UPDATE_MODAL, payload)
  },
  [types.TOGGLE_CREATE_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_MODAL, payload)
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (context, value) => {
    context.commit(types.TOGGLE_DELETE_CONFIRMATION_MODAL, value)
  },
  [types.GET_THONGBAOTOCHUC]: async (context, id) => {
    context.commit(types.GET_THONGBAOTOCHUC)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_THONGBAOTOCHUC_SUCCESS, response.data)
    } else {
      context.commit(types.GET_THONGBAOTOCHUC_FAILED, response.data.message || response.statusText)
    }
  },
  [types.CREATE_THONGBAOTOCHUC]: async (context, payload) => {
    context.commit(types.CREATE_THONGBAOTOCHUC)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/`, payload).catch(error => {
      context.commit(types.CREATE_THONGBAOTOCHUC_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_THONGBAOTOCHUC_FAILED, response.data)
      } else {
        context.commit(types.CREATE_THONGBAOTOCHUC_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_THONGBAOTOCHUC]: async (context, payload) => {
    context.commit(types.UPDATE_THONGBAOTOCHUC)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}`, payload).catch(error => {
      context.commit(types.UPDATE_THONGBAOTOCHUC_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_THONGBAOTOCHUC_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_THONGBAOTOCHUC_SUCCESS, response.data)
      }
    }
  },
  [types.DELETE_THONGBAOTOCHUC]: async (context, payload) => {
    context.commit(types.DELETE_THONGBAOTOCHUC)
    const response = await jwtInterceptor.delete(`${BASE_ENDPOINT}/${payload.id}`).catch(error => {
      context.commit(types.DELETE_THONGBAOTOCHUC_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DELETE_THONGBAOTOCHUC_FAILED, response.data)
      } else {
        context.commit(types.DELETE_THONGBAOTOCHUC_SUCCESS, response.data)
      }
    }
  },
  [types.APPROVE_THONGBAOTOCHUC]: async (context, payload) => {
    context.commit(types.APPROVE_THONGBAOTOCHUC)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.id}/PheDuyet`, payload).catch(error => {
      context.commit(types.APPROVE_THONGBAOTOCHUC_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.APPROVE_THONGBAOTOCHUC_FAILED, response.data)
      } else {
        context.commit(types.APPROVE_THONGBAOTOCHUC_SUCCESS, response.data)
      }
    }
  },
  [types.TOGGLE_DETAIL_MODAL]: async (context, {
    show,
    item
  }) => {
    context.commit(types.TOGGLE_DETAIL_MODAL, show)
    if (show && item.id) {
      context.commit(types.GET_THONGBAOTOCHUC)
      const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${item.id}`).catch(error => {
        context.commit(types.GET_THONGBAOTOCHUC_FAILED, {
          error: true,
          message: `${error.response.status} ${error.response.statusText}`
        })
      })
      if (response) {
        if (response.data.error) {
          context.commit(types.GET_THONGBAOTOCHUC_FAILED, response.data)
        } else {
          context.commit(types.GET_THONGBAOTOCHUC_SUCCESS, response.data)
        }
      }
    }
  },
  [types.GET_YKIENPHEDUYET]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYET)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyet`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYET_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYET_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYETGANNHAT)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyetgannhat`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_FAILED, response.data.message || response.statusText)
    }
  }
}
