import * as types from './actionTypes'

export default {
  [types.GET_LIST_THONGBAOKEHOACH_PAGING]: state => {
    state.isFetchingList = true
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_THONGBAOKEHOACH_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingList = false
    state.dataSourcePaging = Object.freeze(data)
  },
  [types.GET_LIST_THONGBAOKEHOACH_PAGING_FAILED]: (state, err) => {
    state.isFetchingList = false
    state.responseResult = err
    state.dataSourcePaging = {}
  },
  [types.GET_THONGBAOKEHOACH]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.GET_THONGBAOKEHOACH_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.GET_THONGBAOKEHOACH_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.CREATE_THONGBAOKEHOACH]: state => {
    state.isSaving = true
  },
  [types.CREATE_THONGBAOKEHOACH_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_THONGBAOKEHOACH_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_THONGBAOKEHOACH]: state => {
    state.isSaving = true
  },
  [types.UPDATE_THONGBAOKEHOACH_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_THONGBAOKEHOACH_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.DELETE_THONGBAOKEHOACH]: state => {
    state.isDeleting = true
  },
  [types.DELETE_THONGBAOKEHOACH_SUCCESS]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.DELETE_THONGBAOKEHOACH_FAILED]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.APPROVE_THONGBAOKEHOACH]: state => {
    state.isSaving = true
  },
  [types.APPROVE_THONGBAOKEHOACH_SUCCESS]: (state, response) => {
    state.isProving = false
    state.responseResult = response
  },
  [types.APPROVE_THONGBAOKEHOACH_FAILED]: (state, response) => {
    state.isProving = false
    state.responseResult = response
  },
  [types.GET_YKIENPHEDUYET]: state => {
    state.isGetting = true
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYET_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = response.obj
  },
  [types.GET_YKIENPHEDUYET_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: state => {
    state.isGetting = true
    state.yKienPheDuyetGanNhat = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = response.obj
  },
  [types.GET_YKIENPHEDUYETGANNHAT_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = {}
  }
}
