<template>
  <BaseFormGroup
    v-bind="{append, prepend, validFeedback, invalidFeedback,
        tooltipFeedback, description, wrapperClasses, class: computedClasses}">
    <template #label>
      <slot name="label">
        <label v-if="label" :for="id" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>
    <template #input>
      <input v-bind="$attrs" v-on="listeners" :id="id" :type="type" :class="inputClasses"
             :readonly="readonly || plaintext"
             :value="state"
             @input="onInput($event)"
             @change="onChange($event)"/>
    </template>

    <template v-for="slot in slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </BaseFormGroup>
</template>

<script>
import { v4 as uuid4 } from 'uuid'

export default {
  name: 'BaseInput',
  props: {
    validFeedback: String,
    invalidFeedback: Array,
    tooltipFeedback: Boolean,
    description: String,
    append: String,
    prepend: String,
    label: String,
    wasValidated: Boolean,
    isValid: {
      type: [Boolean, Function],
      default: null
    },
    touched: {
      type: Boolean,
      default: false
    },
    addInputClasses: [String, Array, Object],
    addLabelClasses: [String, Array, Object],

    horizontal: [Boolean, Object],
    size: {
      type: String,
      validator: str => ['', 'sm', 'lg'].includes(str)
    },
    addWrapperClasses: [String, Array, Object],
    readonly: Boolean,
    plaintext: Boolean,
    value: [String, Number],
    lazy: {
      type: [Boolean, Number],
      default: 400
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  inheritAttrs: false,
  data () {
    return {
      slots: [
        'prepend',
        'prepend-content',
        'append-content',
        'append',
        'label-after-input',
        'valid-feedback',
        'invalid-feedback',
        'description'
      ],
      id: uuid4(),
      state: this.value,
      syncTimeout: null
    }
  },
  watch: {
    value (val) {
      this.state = val
    }
  },
  computed: {
    listeners () {
      const {
        input,
        change,
        ...listeners
      } = this.$listeners
      return listeners
    },
    haveCustomSize () {
      return ['sm', 'lg'].includes(this.size)
    },
    computedClasses () {
      return [
        'form-group',
        {
          'was-validated': this.wasValidated,
          'form-row': this.isHorizontal
        }
      ]
    },
    labelClasses () {
      return [
        this.addLabelClasses,
        {
          'col-form-label': this.isHorizontal,
          [this.horizontal.label || 'col-sm-3']: this.isHorizontal,
          [`col-form-label-${this.size}`]: this.haveCustomSize
        }
      ]
    },
    customSizeClass () {
      if (this.haveCustomSize && !this.haveWrapper) {
        return `form-control-${this.size}`
      }
      return ''
    },
    inputClasses () {
      return [
        this.inputClass || `form-control${this.plaintext ? '-plaintext' : ''}`,
        this.validationClass,
        this.addInputClasses,
        this.customSizeClass
      ]
    },
    computedIsValid () {
      if (typeof this.isValid === 'function') {
        return this.isValid(this.state)
      }
      return this.isValid
    },
    validationClass () {
      if (typeof this.computedIsValid === 'boolean') {
        if (this.computedIsValid) {
          return 'is-valid'
        }
        if (!this.computedIsValid && this.touched) {
          return 'is-invalid'
        }
      }
      return ''
    },
    isHorizontal () {
      return Boolean(this.horizontal)
    },
    haveInputGroup () {
      return Boolean(
        this.tooltipFeedback ||
        this.append ||
        this.prepend ||
        this.$slots.append ||
        this.$slots.prepend ||
        this.$slots['append-content'] ||
        this.$slots['prepend-content']
      )
    },
    haveWrapper () {
      return this.haveInputGroup || Boolean(this.addWrapperClasses || this.isHorizontal)
    },
    wrapperClasses () {
      if (this.haveWrapper) {
        return [
          this.addWrapperClasses,
          {
            [this.horizontal.input || 'col-sm-9']: this.isHorizontal,
            'input-group': this.haveInputGroup,
            [`input-group-${this.size}`]: this.haveCustomSize
          }
        ]
      }
      return []
    }
  },
  methods: {
    onInput (e) {
      this.state = e.target.value
      this.$emit('input', this.state, e)
      if (this.lazy === true) {
        return
      }
      clearTimeout(this.syncTimeout)
      this.syncTimeout = setTimeout(() => {
        this.$emit('update:value', this.state, e)
      }, this.lazy !== false ? this.lazy : 0)
    },
    onChange (e) {
      this.state = e.target.value
      this.$emit('change', this.state, e)
      this.$emit('update:value', this.state, e)
    }
  }
}
</script>
