import { actions } from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  isFetchingList: false,
  isFetchingSingle: false,
  dataSourcePaging: {},
  isCreateOrUpdateModalOpen: false,
  isCreateModalOpen: false,
  isSaving: false,
  editedItem: {},
  isDeleting: false,
  isApproving: false,
  deletedItem: {},
  yKienPheDuyet: [],
  yKienPheDuyetGanNhat: {},
  isDeleteConfirmationModalOpen: false,
  responseResult: {},
  detailItem: {},
  isDetailModalOpen: false,
  isGetting: false
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
