<template>
  <BaseModal size="sm" color="danger" :show="show" @update:show="toggleModal">
    <template #header>
      <h5 class="modal-title">
        <CIcon name="cil-warning" :width="25"/>
        {{ title }}
      </h5>
      <CButtonClose @click="toggleModal(false)"/>
    </template>
    <template #default>
      <p>Bạn có chắc chắn muốn xóa?</p>
    </template>
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="toggleModal(false)">Hủy bỏ</button>
      <button type="button" class="btn btn-danger" @click="confirmDestroy" :disabled="isDeleting">
        <span v-if="isDeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Xác nhận
      </button>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'DeleteConfirmationModal',
  props: {
    title: String,
    show: Boolean,
    isDeleting: Boolean,
    item: Object
  },
  methods: {
    toggleModal (value) {
      this.$emit('update:show', value)
    },
    confirmDestroy () {
      this.$emit('confirm-destroy', true)
    }
  }
}
</script>
