export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  isFetchingListDieuChinh: state => state.isFetchingListDieuChinh,
  dataSourcePagingDieuChinh: state => state.dataSourcePagingDieuChinh,
  editedItem: state => state.editedItem,
  phulucItem: state => state.phulucItem,
  responseResult: state => state.responseResult,
  formTitle: state => state.editedItem.id ? 'Sửa CT XTĐT của UBND Tỉnh' : 'Thêm CT XTĐT của UBND Tỉnh',
  formPhuLucTitle: state => state.phulucItem.id ? 'Sửa phụ lục CT XTĐT của UBND Tỉnh' : 'Thêm phụ lục CT XTĐT của UBND Tỉnh',
  isSaving: state => state.isSaving,
  isSaving2: state => state.isSaving2,
  isCreateOrUpdateModalOpen: state => state.isCreateOrUpdateModalOpen,
  isCreateOrUpdatePhuLucModalOpen: state => state.isCreateOrUpdatePhuLucModalOpen,
  isDeleting: state => state.isDeleting,
  deletedItem: state => state.deletedItem,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  detailItem: state => state.detailItem,
  isDetailModalOpen: state => state.isDetailModalOpen,
  isPhuLucDetailModalOpen: state => state.isPhuLucDetailModalOpen,
  isPhuLucUpdateModalOpen: state => state.isPhuLucUpdateModalOpen,
  yKienPheDuyet: state => state.yKienPheDuyet,
  yKienPheDuyetGanNhat: state => state.yKienPheDuyetGanNhat,
  tongHopDeXuat1131: state => state.tongHopDeXuat1131,
  tongHopDeXuat1132: state => state.tongHopDeXuat1132,
  tongHopDeXuat1134: state => state.tongHopDeXuat1134,
  historyData: state => state.historyData,
  historyDetail: state => state.historyDetail,
  isFetchingSingle: state => state.isFetchingSingle,
  isApproving: state => state.isApproving,
  isGetting: state => state.isGetting
}
