import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons'
import Toast, { POSITION } from 'vue-toastification'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/plugins/vee-validate'
import BaseModal from '@/components/base/BaseModal'
import BaseModalCustom from '@/components/base/BaseModalCustom'
import BaseTable from '@/components/base/BaseTable'
import BaseFormGroup from '@/components/base/BaseFormGroup'
import BaseInput from '@/components/base/BaseInput'
import BaseSelect from '@/components/base/BaseSelect'
import DeleteConfirmationModal from '@/components/base/DeleteConfirmationModal'
import ApproveConfirmationModal from '@/components/base/ApproveConfirmationModal'
import ApproveConfirmationModal2 from '@/components/base/ApproveConfirmationModal2'
import Multiselect from 'vue-multiselect'
import dotenv from 'dotenv'
import moment from 'moment'
import { DatePicker } from 'v-calendar'
import VueMask from 'v-mask'

require('filepond/dist/filepond.min.css')
require('filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css')

dotenv.config()

Vue.config.productionTip = false
Vue.use(CoreuiVue)
Vue.use(VueMask)

const toastOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 1500
}
Vue.use(Toast, toastOptions)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('BaseModal', BaseModal)
Vue.component('BaseModalCustom', BaseModalCustom)
Vue.component('BaseTable', BaseTable)
Vue.component('BaseFormGroup', BaseFormGroup)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseSelect', BaseSelect)
Vue.component('DeleteConfirmationModal', DeleteConfirmationModal)
Vue.component('multiselect', Multiselect)
Vue.component('v-date-picker', DatePicker)
Vue.component('ApproveConfirmationModal', ApproveConfirmationModal)
Vue.component('ApproveConfirmationModal2', ApproveConfirmationModal2)

Vue.filter('formatDate', function (value, format) {
  if (value) {
    return moment(String(value)).format(format || 'DD/MM/YYYY')
  }
})

Vue.filter('formatNumber', function (v) {
  if (!isNaN(v)) {
    if (v >= 0) {
      return parseInt(v).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').slice(0, -2)
    } else {
      return '-' + parseInt(-v).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').slice(0, -2)
    }
  } else {
    return 0
  }
})
new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')
