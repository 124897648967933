export const GET_LIST_APPLICATION_USER_PAGING = 'GET_LIST_APPLICATION_USER_PAGING'
export const GET_LIST_APPLICATION_USER_PAGING_SUCCESS = 'GET_LIST_APPLICATION_USER_PAGING_SUCCESS'
export const GET_LIST_APPLICATION_USER_PAGING_FAILED = 'GET_LIST_APPLICATION_USER_PAGING_FAILED'

export const GET_APPLICATION_USER = 'GET_APPLICATION_USER'
export const GET_APPLICATION_USER_SUCCESS = 'GET_APPLICATION_USER_SUCCESS'
export const GET_APPLICATION_USER_FAILED = 'GET_APPLICATION_USER_FAILED'

export const CHANGEPASSWORD_APPLICATION_USER = 'CHANGEPASSWORD_APPLICATION_USER'
export const CHANGEPASSWORD_APPLICATION_USER_SUCCESS = 'CHANGEPASSWORD_APPLICATION_USER_SUCCESS'
export const CHANGEPASSWORD_APPLICATION_USER_FAILED = 'CHANGEPASSWORD_APPLICATION_USER_FAILED'

export const CREATE_APPLICATION_USER = 'CREATE_APPLICATION_USER'
export const CREATE_APPLICATION_USER_SUCCESS = 'CREATE_APPLICATION_USER_SUCCESS'
export const CREATE_APPLICATION_USER_FAILED = 'CREATE_APPLICATION_USER_FAILED'

export const UPDATE_APPLICATION_USER = 'UPDATE_APPLICATION_USER'
export const UPDATE_APPLICATION_USER_SUCCESS = 'UPDATE_APPLICATION_USER_SUCCESS'
export const UPDATE_APPLICATION_USER_FAILED = 'UPDATE_APPLICATION_USER_FAILED'

export const DELETE_APPLICATION_USER = 'DELETE_APPLICATION_USER'
export const DELETE_APPLICATION_USER_SUCCESS = 'DELETE_APPLICATION_USER_SUCCESS'
export const DELETE_APPLICATION_USER_FAILED = 'DELETE_APPLICATION_USER_FAILED'
