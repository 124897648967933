import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import DefaultLayout from '@/components/DefaultLayout'
import Login from '@/views/Login'
import Page404 from '@/views/Page404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    meta: {
      label: 'Trang chủ',
      requiredAuth: false
    },
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/Dashboard'),
        meta: { requiredAuth: true }
      },
      {
        path: 'nguoi-dung',
        meta: { label: 'Người dùng' },
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/ApplicationUser')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết người dùng' },
            component: () => import('@/components/application-user/ApplicationUserDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới người dùng' },
            component: () => import('@/components/application-user/ApplicationUserCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật người dùng' },
            component: () => import('@/components/application-user/ApplicationUserUpdate')
          }
        ]
      },
      {
        path: 'nhom-quyen',
        meta: { label: 'Nhóm quyền' },
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/ApplicationRole')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết nhóm quyền' },
            component: () => import('@/components/application-role/ApplicationRoleDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới nhóm quyền' },
            component: () => import('@/components/application-role/ApplicationRoleCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật nhóm quyền' },
            component: () => import('@/components/application-role/ApplicationRoleUpdate')
          }
        ]
      },
      {
        path: 'danh-muc',
        meta: { label: 'Danh mục' },
        redirect: '/danh-muc/loai-hoat-dong',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'loai-hoat-dong',
            meta: { label: 'Loại hoạt động' },
            component: () => import('@/views/danh-muc/LoaiHoatDong')
          },
          {
            path: 'don-vi',
            meta: { label: 'Đơn vị' },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                component: () => import('@/views/danh-muc/DonVi')
              },
              {
                path: ':id(\\d+)',
                meta: { label: 'Chi tiết đơn vị' },
                component: () => import('@/components/danh-muc/don-vi/DonViDetail')
              },
              {
                path: 'them-moi',
                meta: { label: 'Thêm mới đơn vị' },
                component: () => import('@/components/danh-muc/don-vi/DonViCreate')
              },
              {
                path: ':id(\\d+)/cap-nhat',
                meta: { label: 'Cập nhật đơn vị' },
                component: () => import('@/components/danh-muc/don-vi/DonViUpdate')
              }
            ]
          },
          {
            path: 'nganh-linh-vuc-keu-goi-dau-tu',
            meta: { label: 'Ngành lĩnh vực kêu gọi đầu tư' },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                component: () => import('@/views/danh-muc/NganhLinhVucKeuGoiDauTu')
              },
              {
                path: ':id(\\d+)',
                meta: { label: 'Chi tiết ngành lĩnh vực kêu gọi đầu tư' },
                component: () => import('@/components/danh-muc/nganh-linh-vuc-keu-goi-dau-tu/NganhLinhVucKeuGoiDauTuDetail')
              },
              {
                path: 'them-moi',
                meta: { label: 'Thêm mới ngành lĩnh vực kêu gọi đầu tư' },
                component: () => import('@/components/danh-muc/nganh-linh-vuc-keu-goi-dau-tu/NganhLinhVucKeuGoiDauTuCreate')
              },
              {
                path: ':id(\\d+)/cap-nhat',
                meta: { label: 'Cập nhật ngành lĩnh vực kêu gọi đầu tư' },
                component: () => import('@/components/danh-muc/nganh-linh-vuc-keu-goi-dau-tu/NganhLinhVucKeuGoiDauTuUpdate')
              }
            ]
          },
          {
            path: 'tinh-thanh-pho',
            meta: { label: 'Tỉnh thành phố' },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                component: () => import('@/views/danh-muc/TinhThanhPho')
              },
              {
                path: ':id(\\d+)',
                meta: { label: 'Chi tiết tỉnh/thành phố' },
                component: () => import('@/components/danh-muc/tinh-thanh-pho/TinhThanhPhoDetail')
              },
              {
                path: 'them-moi',
                meta: { label: 'Thêm mới tỉnh/thành phố' },
                component: () => import('@/components/danh-muc/tinh-thanh-pho/TinhThanhPhoCreate')
              },
              {
                path: ':id(\\d+)/cap-nhat',
                meta: { label: 'Cập nhật tỉnh/thành phố' },
                component: () => import('@/components/danh-muc/tinh-thanh-pho/TinhThanhPhoUpdate')
              }
            ]
          },
          {
            path: 'vung-kinh-te',
            meta: { label: 'Vùng kinh tế' },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                component: () => import('@/views/danh-muc/VungKinhTe')
              },
              {
                path: ':id(\\d+)',
                meta: { label: 'Chi tiết vùng kinh tế' },
                component: () => import('@/components/danh-muc/vung-kinh-te/VungKinhTeDetail')
              },
              {
                path: 'them-moi',
                meta: { label: 'Thêm mới vùng kinh tế' },
                component: () => import('@/components/danh-muc/vung-kinh-te/VungKinhTeCreate')
              },
              {
                path: ':id(\\d+)/cap-nhat',
                meta: { label: 'Cập nhật vùng kinh tế' },
                component: () => import('@/components/danh-muc/vung-kinh-te/VungKinhTeUpdate')
              }
            ]
          },
          {
            path: 'quoc-gia',
            meta: { label: 'Quốc gia' },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                component: () => import('@/views/danh-muc/QuocGia')
              },
              {
                path: ':id(\\d+)',
                meta: { label: 'Chi tiết quốc gia' },
                component: () => import('@/components/danh-muc/quoc-gia/QuocGiaDetail')
              },
              {
                path: 'them-moi',
                meta: { label: 'Thêm mới quốc gia' },
                component: () => import('@/components/danh-muc/quoc-gia/QuocGiaCreate')
              },
              {
                path: ':id(\\d+)/cap-nhat',
                meta: { label: 'Cập nhật quốc gia' },
                component: () => import('@/components/danh-muc/quoc-gia/QuocGiaUpdate')
              }
            ]
          }
          // {
          //   path: 'thong-bao-ke-hoach-xt-hon-hop',
          //   meta: { label: 'Thông báo kế hoạch XT hỗn hợp' },
          //   component: {
          //     render (c) {
          //       return c('router-view')
          //     }
          //   },
          //   children: [
          //     {
          //       path: '',
          //       component: () => import('@/views/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoach')
          //     },
          //     {
          //       path: ':id(\\d+)',
          //       meta: { label: 'Chi tiết thông báo kế hoạch hoạt động XT hỗn hợp' },
          //       component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachDetail')
          //     },
          //     {
          //       path: 'them-moi',
          //       meta: { label: 'Thêm mới thông báo kế hoạch hoạt động XT hỗn hợp' },
          //       component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachCreate')
          //     },
          //     {
          //       path: ':id(\\d+)/cap-nhat',
          //       meta: { label: 'Cập nhật thông báo kế hoạch hoạt động XT hỗn hợp' },
          //       component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachUpdate')
          //     }
          //   ]
          // }
        ]
      },
      {
        path: 'thong-bao-ke-hoach-xt-hon-hop',
        meta: { label: 'Thông báo kế hoạch XT hỗn hợp' },
        redirect: '/thong-bao-ke-hoach-xt-hon-hop',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoach')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết thông báo kế hoạch hoạt động XT hỗn hợp' },
            component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới thông báo kế hoạch hoạt động XT hỗn hợp' },
            component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật thông báo kế hoạch hoạt động XT hỗn hợp' },
            component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachUpdate')
          },
          {
            path: 'bieu-in',
            meta: { label: 'Biểu in thông báo kế hoạch hoạt động XT hỗn hợp' },
            component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachPrint')
          },
          {
            path: ':id(\\d+)/bieu-in',
            meta: { label: 'Biểu in thông báo kế hoạch hoạt động XT hỗn hợp' },
            component: () => import('@/components/VanBanTBKHTCHDXTHHDTTMDLNGKT/ThongBaoKeHoachPrint')
          }
        ]
      },
      {
        path: 'thong-bao-to-chuc-hd-xtdt',
        meta: { label: 'Thông báo tổ chức hoạt động XTĐT không sử dụng ngân sách nhà nước' },
        redirect: '/thong-bao-to-chuc-hd-xtdt',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/VanBanTBTCHDXTDTKSDNSNN/ThongBaoToChuc')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết thông báo kế hoạch hoạt động XT đầu tư không sử dụng vốn nhà nước' },
            component: () => import('@/components/VanBanTBTCHDXTDTKSDNSNN/ThongBaoToChucDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới thông báo kế hoạch hoạt động XT đầu tư không sử dụng vốn nhà nước' },
            component: () => import('@/components/VanBanTBTCHDXTDTKSDNSNN/ThongBaoToChucCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật thông báo kế hoạch hoạt động XT đầu tư không sử dụng vốn nhà nước' },
            component: () => import('@/components/VanBanTBTCHDXTDTKSDNSNN/ThongBaoToChucUpdate')
          },
          {
            path: 'bieu-in',
            meta: { label: 'Biểu in thông báo kế hoạch hoạt động XT đầu tư không sử dụng vốn nhà nước' },
            component: () => import('@/components/VanBanTBTCHDXTDTKSDNSNN/ThongBaoToChucPrint')
          }
        ]
      },

      // Phương án XTDT trong HDDN Cấp cao
      {
        path: 'phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao',
        meta: { label: 'Phương án XTĐT trong hoạt động đối ngoại cấp cao' },
        redirect: '/phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/PhuongAnXTDTTrongHDDNCapCao/ThongBaoKeHoach')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết phương án XTĐT trong hoạt động đối ngoại cấp cao' },
            component: () => import('@/components/PhuongAnXTDTTrongHDDNCapCao/ThongBaoKeHoachDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới phương án XTĐT trong hoạt động đối ngoại cấp cao' },
            component: () => import('@/components/PhuongAnXTDTTrongHDDNCapCao/ThongBaoKeHoachCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật phương án XTĐT trong hoạt động đối ngoại cấp cao' },
            component: () => import('@/components/PhuongAnXTDTTrongHDDNCapCao/ThongBaoKeHoachUpdate')
          },
          {
            path: 'bieu-in',
            meta: { label: 'Biểu in phương án XTĐT trong hoạt động đối ngoại cấp cao' },
            component: () => import('@/components/PhuongAnXTDTTrongHDDNCapCao/ThongBaoKeHoachPrint')
          }
        ]
      },
      // ============================================================================

      {
        path: 'de-xuat-ct-xtdt',
        meta: { label: 'CT XTĐT của Bộ, cơ quan ngang Bộ' },
        redirect: '/de-xuat-ct-xtdt',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/DeXuatCTXTDT_BoNganh/DeXuatCTXTDT')
          },
          {
            path: 'dieu-chinh',
            component: () => import('@/views/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTDieuChinh')
          },
          {
            path: 'bao-cao',
            component: () => import('@/views/DeXuatCTXTDT_BoNganh/BaoCaoDeXuatCTXTDT')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết chương trình xúc tiến đầu tư của Bộ, Ngành' },
            component: () => import('@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới chương trình xúc tiến đầu tư của Bộ, Ngành' },
            component: () => import('@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật chương trình xúc tiến đầu tư của Bộ, Ngành' },
            component: () => import('@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTUpdate')
          },
          {
            path: ':id(\\d+)/dieu-chinh',
            meta: { label: 'Điều chỉnh chương trình xúc tiến đầu tư của Bộ, Ngành' },
            component: () => import('@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTUpdate2')
          },
          {
            path: ':id(\\d+)/lich-su-dieu-chinh',
            meta: { label: 'Lịch sử điều chỉnh chương trình xúc tiến đầu tư của Bộ, Ngành' },
            component: () => import('@/views/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTBoNganhHistory')
          },
          {
            path: 'bieu-in',
            meta: { label: 'Biểu in CT XTĐT Bộ/ngành' },
            component: () => import('@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTPrint')
          },
          {
            path: 'bieu-in-phu-luc',
            meta: { label: 'Biểu in Phụ lục CT XTĐT Bộ/ngành' },
            component: () => import('@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTPhuLucPrint')
          }
          // {
          //   path: ':id(\\d+)/:id2(\\d+)/chi-tiet-dieu-chinh',
          //   meta: { label: 'Chi tiết điều chỉnh chương trình xúc tiến đầu tư của Bộ, Ngành' },
          //   component: () => import('@/views/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTBoNganhHistoryChiTiet')
          // }
        ]
      },
      {
        path: 'de-xuat-ct-xtdt-dia-phuong',
        meta: { label: 'CT XTĐT của UBND Địa phương' },
        redirect: '/de-xuat-ct-xtdt-dia-phuong',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDT')
          },
          {
            path: 'dieu-chinh',
            component: () => import('@/views/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTDieuChinh')
          },
          {
            path: 'bao-cao',
            component: () => import('@/views/DeXuatCTXTDT_DiaPhuong/BaoCaoDeXuatCTXTDT')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết chương trình xúc tiến đầu tư của Địa phương' },
            component: () => import('@/components/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới chương trình xúc tiến đầu tư của Địa phương' },
            component: () => import('@/components/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật chương trình xúc tiến đầu tư của Địa phương' },
            component: () => import('@/components/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTUpdate')
          },
          {
            path: ':id(\\d+)/dieu-chinh',
            meta: { label: 'Điều chỉnh chương trình xúc tiến đầu tư của Địa phương' },
            component: () => import('@/components/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTUpdate2')
          },
          {
            path: ':id(\\d+)/lich-su-dieu-chinh',
            meta: { label: 'Lịch sử điều chỉnh chương trình xúc tiến đầu tư của Bộ, Ngành' },
            component: () => import('@/views/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTDiaPhuongHistory')
          },
          {
            path: 'bieu-in',
            meta: { label: 'Biểu in CT XTĐT Địa phương' },
            component: () => import('@/components/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTPrint')
          },
          {
            path: 'bieu-in-phu-luc',
            meta: { label: 'Biểu in Phụ lục CT XTĐT Địa phương' },
            component: () => import('@/components/DeXuatCTXTDT_DiaPhuong/DeXuatCTXTDTPhuLucPrint')
          }
        ]
      },
      {
        path: 'de-xuat-ct-xtdt-qg',
        meta: { label: 'CT XTĐT Quốc gia' },
        redirect: '/de-xuat-ct-xtdt-qg',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/DeXuatCTXTDTQG/DeXuatCTXTDTQG')
          },
          {
            path: 'dieu-chinh',
            component: () => import('@/views/DeXuatCTXTDTQG/DeXuatCTXTDTQGDieuChinh')
          },
          {
            path: 'bao-cao',
            component: () => import('@/views/DeXuatCTXTDTQG/BaoCaoDeXuatCTXTDTQG')
          },
          {
            path: ':id(\\d+)',
            meta: { label: 'Chi tiết chương trình xúc tiến đầu tư quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGDetail')
          },
          {
            path: 'them-moi',
            meta: { label: 'Thêm mới chương trình xúc tiến đầu tư quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGCreate')
          },
          {
            path: ':id(\\d+)/cap-nhat',
            meta: { label: 'Cập nhật chương trình xúc tiến đầu tư quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGUpdate')
          },
          {
            path: ':id(\\d+)/dieu-chinh',
            meta: { label: 'Điều chỉnh chương trình xúc tiến đầu tư quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGUpdate2')
          },
          {
            path: ':id(\\d+)/lich-su-dieu-chinh',
            meta: { label: 'Lịch sử điều chỉnh chương trình xúc tiến đầu tư quốc gia' },
            component: () => import('@/views/DeXuatCTXTDTQG/DeXuatCTXTDTDQGHistory')
          },
          {
            path: 'bieu-in',
            meta: { label: 'Biểu in CT XTĐT Quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGPrint')
          },
          {
            path: 'bieu-in-phu-luc',
            meta: { label: 'Biểu in Phụ lục CT XTĐT Quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGPhuLucPrint')
          },
          {
            path: 'bieu-in-de-an',
            meta: { label: 'Biểu in Đề án CT XTĐT Quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGDeAnPrint')
          },
          {
            path: 'bieu-in-du-toan',
            meta: { label: 'Biểu in Dự toán kinh phí CT XTĐT Quốc gia' },
            component: () => import('@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGDuToanPrint')
          }
        ]
      },
      {
        path: 'changpassword',
        component: () => import('@/views/ChangePassword'),
        meta: { label: 'Đổi mật khẩu' }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiredAuth: false }
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: Page404,
    meta: { requiredAuth: false }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = store.getters['auth/isTokenActive']
  if (to.meta.requiredAuth) {
    if (auth) {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})

export default router
