export const GET_LIST_DEXUATCTXTDT_PAGING = 'GET_LIST_DEXUATCTXTDT_PAGING'
export const GET_LIST_DEXUATCTXTDT_PAGING_SUCCESS = 'GET_LIST_DEXUATCTXTDT_PAGING_SUCCESS'
export const GET_LIST_DEXUATCTXTDT_PAGING_FAILED = 'GET_LIST_DEXUATCTXTDT_PAGING_FAILED'

export const GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING = 'GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING'
export const GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_SUCCESS = 'GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_SUCCESS'
export const GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_FAILED = 'GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_FAILED'

export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL'
export const GET_DEXUATCTXTDT = 'GET_DEXUATCTXTDT'
export const GET_DEXUATCTXTDT_SUCCESS = 'GET_DEXUATCTXTDT_SUCCESS'
export const GET_DEXUATCTXTDT_FAILED = 'GET_DEXUATCTXTDT_FAILED'

export const TOGGLE_CREATE_UPDATE_MODAL = 'TOGGLE_CREATE_UPDATE_MODAL'
export const TOGGLE_CREATE_UPDATE_PHULUC_MODAL = 'TOGGLE_CREATE_UPDATE_PHULUC_MODAL'
export const TOGGLE_UPDATE_PHULUC_MODAL = 'TOGGLE_UPDATE_PHULUC_MODAL'

export const CREATE_DEXUATCTXTDT = 'CREATE_DEXUATCTXTDT'
export const CREATE_DEXUATCTXTDT_SUCCESS = 'CREATE_DEXUATCTXTDT_SUCCESS'
export const CREATE_DEXUATCTXTDT_FAILED = 'CREATE_DEXUATCTXTDT_FAILED'

export const CREATE_CTXTDTPHULUCCUABOUBNDTINH = 'CREATE_CTXTDTPHULUCCUABOUBNDTINH'
export const CREATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS = 'CREATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS'
export const CREATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED = 'CREATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED'

export const UPDATE_DEXUATCTXTDT = 'UPDATE_DEXUATCTXTDT'
export const UPDATE_DEXUATCTXTDT_SUCCESS = 'UPDATE_DEXUATCTXTDT_SUCCESS'
export const UPDATE_DEXUATCTXTDT_FAILED = 'UPDATE_DEXUATCTXTDT_FAILED'

export const DIEUCHINH_DEXUATCTXTDT = 'DIEUCHINH_DEXUATCTXTDT'
export const DIEUCHINH_DEXUATCTXTDT_SUCCESS = 'DIEUCHINH_DEXUATCTXTDT_SUCCESS'
export const DIEUCHINH_DEXUATCTXTDT_FAILED = 'DIEUCHINH_DEXUATCTXTDT_FAILED'

export const UPDATE_CTXTDTPHULUCCUABOUBNDTINH = 'UPDATE_CTXTDTPHULUCCUABOUBNDTINH'
export const UPDATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS = 'UPDATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS'
export const UPDATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED = 'UPDATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED'

export const DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH = 'DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH'
export const DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS = 'DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS'
export const DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_FAILED = 'DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_FAILED'

export const TOGGLE_DELETE_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CONFIRMATION_MODAL'
export const DELETE_DEXUATCTXTDT = 'DELETE_DEXUATCTXTDT'
export const DELETE_DEXUATCTXTDT_SUCCESS = 'DELETE_DEXUATCTXTDT_SUCCESS'
export const DELETE_DEXUATCTXTDT_FAILED = 'DELETE_DEXUATCTXTDT_FAILED'

export const APPROVE_DEXUATCTXTDT = 'APPROVE_DEXUATCTXTDT'
export const APPROVE_DEXUATCTXTDT_SUCCESS = 'APPROVE_DEXUATCTXTDT_SUCCESS'
export const APPROVE_DEXUATCTXTDT_FAILED = 'APPROVE_DEXUATCTXTDT_FAILED'

export const GET_YKIENPHEDUYET = 'GET_YKIENPHEDUYET'
export const GET_YKIENPHEDUYET_SUCCESS = 'GET_YKIENPHEDUYET_SUCCESS'
export const GET_YKIENPHEDUYET_FAILED = 'GET_YKIENPHEDUYET_FAILED'

export const GET_YKIENPHEDUYETGANNHAT = 'GET_YKIENPHEDUYETGANNHAT'
export const GET_YKIENPHEDUYETGANNHAT_SUCCESS = 'GET_YKIENPHEDUYETGANNHAT_SUCCESS'
export const GET_YKIENPHEDUYETGANNHAT_FAILED = 'GET_YKIENPHEDUYETGANNHAT_FAILED'

export const GET_TONGHOPDEXUAT1121 = 'GET_TONGHOPDEXUAT1121'
export const GET_TONGHOPDEXUAT1121_SUCCESS = 'GET_TONGHOPDEXUAT1121_SUCCESS'
export const GET_TONGHOPDEXUAT1121_FAILED = 'GET_TONGHOPDEXUAT1121_FAILED'

export const GET_TONGHOPDEXUAT1122 = 'GET_TONGHOPDEXUAT1122'
export const GET_TONGHOPDEXUAT1122_SUCCESS = 'GET_TONGHOPDEXUAT1122_SUCCESS'
export const GET_TONGHOPDEXUAT1122_FAILED = 'GET_TONGHOPDEXUAT1122_FAILED'

export const GET_TONGHOPDEXUAT1124 = 'GET_TONGHOPDEXUAT1124'
export const GET_TONGHOPDEXUAT1124_SUCCESS = 'GET_TONGHOPDEXUAT11241_SUCCESS'
export const GET_TONGHOPDEXUAT1124_FAILED = 'GET_TONGHOPDEXUAT1124_FAILED'

export const GET_HISTORY = 'GET_HISTORY'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const GET_HISTORY_FAILED = 'GET_HISTORY_FAILED'
export const TOGGLE_HISTORY_MODAL = 'TOGGLE_HISTORY_MODAL'

export const GET_HISTORYDETAIL = 'GET_HISTORYDETAIL'
export const GET_HISTORYDETAIL_SUCCESS = 'GET_HISTORYDETAIL_SUCCESS'
export const GET_HISTORYDETAIL_FAILED = 'GET_HISTORYDETAIL_FAILED'
export const TOGGLE_HISTORYDETAIL_MODAL = 'TOGGLE_HISTORYDETAIL_MODAL'
