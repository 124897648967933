import axios from 'axios'
import store from '../store'
import appConfig from '../shared/appConfig'
import qs from 'qs'

const jwtInterceptor = axios.create({})

jwtInterceptor.interceptors.request.use((config) => {
  const authData = store.getters['auth/getAuthData']
  config.headers.common.Authorization = `Bearer ${authData.accessToken}`
  return config
})

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      const authData = store.getters['auth/getAuthData']
      const payload = {
        grant_type: 'refresh_token',
        refresh_token: authData.refreshToken,
        client_id: appConfig.CLIENT_ID,
        client_secret: appConfig.CLIENT_SECRET
      }
      const response = await axios.post(
        `${appConfig.BASE_HOST}/token`,
        qs.stringify(payload)
      )
      await store.dispatch('auth/saveTokensToStorage', response.data)
      error.config.headers.Authorization = `Bearer ${response.data.access_token}`
      return axios(error.config)
    } else {
      return Promise.reject(error)
    }
  }
)

export default jwtInterceptor
