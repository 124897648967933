export const GET_LIST_QUOCGIA_PAGING = 'GET_LIST_QUOCGIA_PAGING'
export const GET_LIST_QUOCGIA_PAGING_SUCCESS = 'GET_LIST_QUOCGIA_PAGING_SUCCESS'
export const GET_LIST_QUOCGIA_PAGING_FAILED = 'GET_LIST_QUOCGIA_PAGING_FAILED'

export const GET_QUOCGIA = 'GET_QUOCGIA'
export const GET_QUOCGIA_SUCCESS = 'GET_QUOCGIA_SUCCESS'
export const GET_QUOCGIA_FAILED = 'GET_QUOCGIA_FAILED'

export const CREATE_QUOCGIA = 'CREATE_QUOCGIA'
export const CREATE_QUOCGIA_SUCCESS = 'CREATE_QUOCGIA_SUCCESS'
export const CREATE_QUOCGIA_FAILED = 'CREATE_QUOCGIA_FAILED'

export const UPDATE_QUOCGIA = 'UPDATE_QUOCGIA'
export const UPDATE_QUOCGIA_SUCCESS = 'UPDATE_QUOCGIA_SUCCESS'
export const UPDATE_QUOCGIA_FAILED = 'UPDATE_QUOCGIA_FAILED'

export const DELETE_QUOCGIA = 'DELETE_QUOCGIA'
export const DELETE_QUOCGIA_SUCCESS = 'DELETE_QUOCGIA_SUCCESS'
export const DELETE_QUOCGIA_FAILED = 'DELETE_QUOCGIA_FAILED'
