import Vue from 'vue'
import Vuex from 'vuex'

import uiModule from './modules/ui'
import authModule from './modules/auth'

import loaiHoatDongModule from './modules/loai-hoat-dong'
import donViModule from './modules/don-vi'
import nganhLinhVucKeuGoiDauTuModule from './modules/nganh-linh-vuc-keu-goi-dau-tu'
import quocGiaModule from './modules/quoc-gia'
import tinhThanhPhoModule from './modules/tinh-thanh-pho'
import vungKinhTeModule from './modules/vung-kinh-te'
import applicationRoleModule from './modules/application-role'
import applicationUserModule from './modules/application-user'
import thongBaoKeHoachModule from './modules/VanBanTBKHTCHDXTHHDTTMDLNGKT'
import phuongAnModule from './modules/PhuongAnXTDTTrongHDDNCapCao'
import thongBaoToChucModule from './modules/VanBanTBTCHDXTDTKSDNSNN'
import deXuatCTXTDTModule from './modules/DeXuatCTXTDT_BoNganh'
import deXuatCTXTDTDiaPhuongModule from './modules/DeXuatCTXTDT_DiaPhuong'
import deXuatCTXTDTQGModule from './modules/DeXuatCTXTDTQG'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui: uiModule,
    auth: authModule,
    loaiHoatDong: loaiHoatDongModule,
    donVi: donViModule,
    tinhThanhPho: tinhThanhPhoModule,
    quocGia: quocGiaModule,
    nganhLinhVucKeuGoiDauTu: nganhLinhVucKeuGoiDauTuModule,
    vungKinhTe: vungKinhTeModule,
    applicationRole: applicationRoleModule,
    applicationUser: applicationUserModule,
    thongBaoKeHoach: thongBaoKeHoachModule,
    thongBaoToChuc: thongBaoToChucModule,
    phuongAnModule,
    deXuatCTXTDT: deXuatCTXTDTModule,
    deXuatCTXTDTDiaPhuong: deXuatCTXTDTDiaPhuongModule,
    deXuatCTXTDTQG: deXuatCTXTDTQGModule,
    printThongBaoToChuc: {
      show: false,
      style: { display: 'none' },
      class: 'ms-motion-slideUpOut',
      data: {}
    }
  }
})
