export const GET_LIST_APPLICATION_ROLE_PAGING = 'GET_LIST_APPLICATION_ROLE_PAGING'
export const GET_LIST_APPLICATION_ROLE_PAGING_SUCCESS = 'GET_LIST_APPLICATION_ROLE_PAGING_SUCCESS'
export const GET_LIST_APPLICATION_ROLE_PAGING_FAILED = 'GET_LIST_APPLICATION_ROLE_PAGING_FAILED'

export const GET_APPLICATION_ROLE = 'GET_APPLICATION_ROLE'
export const GET_APPLICATION_ROLE_SUCCESS = 'GET_APPLICATION_ROLE_SUCCESS'
export const GET_APPLICATION_ROLE_FAILED = 'GET_APPLICATION_ROLE_FAILED'

export const CREATE_APPLICATION_ROLE = 'CREATE_APPLICATION_ROLE'
export const CREATE_APPLICATION_ROLE_SUCCESS = 'CREATE_APPLICATION_ROLE_SUCCESS'
export const CREATE_APPLICATION_ROLE_FAILED = 'CREATE_APPLICATION_ROLE_FAILED'

export const UPDATE_APPLICATION_ROLE = 'UPDATE_APPLICATION_ROLE'
export const UPDATE_APPLICATION_ROLE_SUCCESS = 'UPDATE_APPLICATION_ROLE_SUCCESS'
export const UPDATE_APPLICATION_ROLE_FAILED = 'UPDATE_APPLICATION_ROLE_FAILED'

export const DELETE_APPLICATION_ROLE = 'DELETE_APPLICATION_ROLE'
export const DELETE_APPLICATION_ROLE_SUCCESS = 'DELETE_APPLICATION_ROLE_SUCCESS'
export const DELETE_APPLICATION_ROLE_FAILED = 'DELETE_APPLICATION_ROLE_FAILED'
