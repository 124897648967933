<template>
  <!--  <div class="c-app flex-row align-items-center">-->
  <!--    <CContainer>-->
  <!--      <CRow class="justify-content-center">-->
  <!--        <CCol md="4">-->
  <!--          <CCardGroup>-->
  <!--            <CCard class="p-4">-->
  <!--              <CCardBody>-->
  <!--                <CForm>-->
  <!--                  <div style="display: flex">-->
  <!--                    <img class="align-middle" src="@/assets/images/Logo_FIA_2014.jpg" alt="FIA Logo full" height="43">-->
  <!--                    <p class="ml-3 fs-18">Hệ thống Thông tin quốc gia về Xúc tiến đầu tư</p>-->
  <!--                  </div>-->
  <!--                  <h5>Đăng nhập</h5>-->
  <!--&lt;!&ndash;                  <p class="text-muted">Đăng nhập</p>&ndash;&gt;-->
  <!--                  <CInput placeholder="Tài khoản" autocomplete="username" v-model="username">-->
  <!--                    <template #prepend-content>-->
  <!--                      <CIcon name="cil-user"/>-->
  <!--                    </template>-->
  <!--                  </CInput>-->
  <!--                  <CInput placeholder="Mật khẩu" type="password" autocomplete="password" v-model="password">-->
  <!--                    <template #prepend-content>-->
  <!--                      <CIcon name="cil-lock-locked"/>-->
  <!--                    </template>-->
  <!--                  </CInput>-->
  <!--                  <CRow class="form-group" v-if="loginStatus === 'failed'">-->
  <!--                    <CCol col="12" class="text-center text-danger">-->
  <!--                      Thông tin đăng nhập không đúng!-->
  <!--                    </CCol>-->
  <!--                  </CRow>-->
  <!--                  <CRow>-->
  <!--                    <CCol col="12" class="text-center">-->
  <!--                      <CButton color="primary" class="px-4" @click="login" :disabled="isLoading">-->
  <!--                        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"-->
  <!--                              aria-hidden="true"></span> Đăng nhập-->
  <!--                      </CButton>-->
  <!--                    </CCol>-->
  <!--                  </CRow>-->
  <!--                </CForm>-->
  <!--              </CCardBody>-->
  <!--            </CCard>-->
  <!--          </CCardGroup>-->
  <!--        </CCol>-->
  <!--      </CRow>-->
  <!--    </CContainer>-->
  <!--  </div>-->
  <main>
    <div class="login-form vpi">
      <div class="login-form-bg"></div>
      <div class="login">
        <div class="login-bg"></div>
        <div class="login-content">
<!--          <div class="login-logo">-->
<!--            <img class="align-middle" src="@/assets/images/Logo_FIA_2014.jpg" alt="FIA Logo">-->
<!--          </div>-->
          <div style="display: flex">
            <img class="align-middle" src="@/assets/images/Logo_FIA_2014.jpg" alt="FIA Logo full" height="25%">
            <p class="login-title ml-3">Hệ thống Thông tin quốc gia về Xúc tiến đầu tư</p>
          </div>
          <div class="mt-3">
            <div class="username">
<!--              <i class="ms-Icon ms-Icon&#45;&#45;Contact"></i>-->
              <input v-model="username" type="text" placeholder="Tài khoản">
            </div>
            <div class="password">
<!--              <i class="ms-Icon ms-Icon&#45;&#45;Contact"></i>-->
              <input v-model="password" type="password" placeholder="Mật khẩu">
            </div>
            <CRow class="form-group" v-if="loginStatus === 'failed'">
              <CCol col="12" class="text-center text-danger">
                Thông tin đăng nhập không đúng!
              </CCol>
            </CRow>
            <div class="submit-button">
              <button type="button" @click="login">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Đăng nhập
              </button>
            </div>
            <div class="info">
              <div style="display: flex">
                <CIcon name="cil-location-pin" />
                <span><span class="font-weight-bold">Địa chỉ:</span> 6B Hoàng Diệu, Quán Thánh, Ba Đình, Hà Nội</span>
              </div>
              <div style="display: flex">
                <CIcon name="cil-task" />
                <span><span class="font-weight-bold">Điện thoại:</span> 080-48461; <span class="font-weight-bold">Fax:</span> 0243.7343.769</span>
              </div>
              <div style="display: flex">
                <CIcon name="cil-laptop" />
                <span><span class="font-weight-bold">Email:</span> tonghop.dtnn@mpi.gov.vn</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters('auth', {
      loginStatus: 'getLoginStatus',
      isLoading: 'isLoading'
    })
  },
  methods: {
    ...mapActions('auth', { actionLogin: 'login' }),
    async login () {
      await this.actionLogin({
        username: this.username,
        password: this.password
      })
      if (this.loginStatus === 'success') {
        await this.$router.push('/dashboard')
        this.$toast.success('Đăng nhập thành công')
      }
    }
  }
}
</script>
<style scoped>
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

main {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url('../assets/images/bg.jpg');
  background-size: cover;
}

.login-form {
  font-family: Segoe UI WestEuropean, Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  position: relative;
  margin: auto;
  max-width: 440px;
  width: 440px;
  padding: 44px;
  /*! margin-bottom: 28px; */
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  min-width: 320px;
  min-height: 338px;
  overflow: hidden;
  top: 0;
  bottom: 0;
}

.vpi {
  background-image: url("/img/login-bg-vpi.png");
  background-size: cover;
}

.login-form.border {
  border-radius: 7px;
}

.login-form .login-logo {
  width: 25%;
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
}

.login-form .login-title {
  font-size: 15pt !important;
  font-family: "Times New Roman" !important;
  color: #db2927;
}

.login-form .login-logo img {
  max-width: 50%;
}

.login-form.vpi .login-logo img {
  max-width: 100%;
  width: 300px;
}

.login-form .login-content input {
  border: 1px solid #b6b7b9;
  padding: 14px 14px 14px 42px;
  width: 100%;
  color: rgb(27, 27, 27);
  font-size: 15px;
  box-shadow: none !important;
  outline: none;
  border-radius: 4px;
  border-image-source: none;
  overflow: hidden;
}

.login-form .login-content input:hover,
.login-form .login-content input:focus {
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #f89021, #db2927);
  border-color: transparent !important;
}

.login-form.vpi .login-content input:hover,
.login-form.vpi .login-content input:focus {
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #a55018, #d68847);
  border-color: transparent !important;
}

.login-form .login-content .username,
.login-form .login-content .password {
  position: relative;
}

.login-form .login-content .username {
  margin-bottom: 15px;
}

.login-form .login-content .ms-Icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.login-form button {
  margin-top: 30px;
  width: 50%;
  border-radius: 19px;
  box-shadow: none;
  border: 0;
  padding: 10px;
  font-size: 15px;
  background: linear-gradient(90deg, #f89021, #db2927 80%);
  color: #fff !important;
}

.sps-am-ban button {
  background: #fff;
  color: rgb(27, 27, 27) !important;
}

.login-form .submit-button {
  text-align: center;
}

.login-form.vpi button {
  background: #1A3E70;
  color: #fff;
  border-radius: 4px;
}

.login-form .info {
  text-align: left;
}

</style>
