import * as types from './actionTypes'

export default {
  [types.GET_LIST_THONGBAOTOCHUC_PAGING]: state => {
    state.isFetchingList = true
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_THONGBAOTOCHUC_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingList = false
    state.dataSourcePaging = Object.freeze(data)
  },
  [types.GET_LIST_THONGBAOTOCHUC_PAGING_FAILED]: (state, err) => {
    state.isFetchingList = false
    state.responseResult = err
    state.dataSourcePaging = {}
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.editedItem = Object.assign({}, item)
    state.isCreateOrUpdateModalOpen = show
  },
  [types.TOGGLE_CREATE_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.editedItem = Object.assign({}, item)
    state.isCreateModalOpen = show
  },
  [types.CREATE_THONGBAOTOCHUC]: state => {
    state.isSaving = true
    state.editedItem = {}
  },
  [types.CREATE_THONGBAOTOCHUC_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_THONGBAOTOCHUC_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_THONGBAOTOCHUC]: state => {
    state.isSaving = true
  },
  [types.UPDATE_THONGBAOTOCHUC_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.UPDATE_THONGBAOTOCHUC_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.deletedItem = Object.assign({}, item)
    state.isDeleteConfirmationModalOpen = show
  },
  [types.DELETE_THONGBAOTOCHUC]: state => {
    state.isDeleting = true
  },
  [types.DELETE_THONGBAOTOCHUC_SUCCESS]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
    state.deletedItem = {}
  },
  [types.DELETE_THONGBAOTOCHUC_FAILED]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.TOGGLE_DETAIL_MODAL]: (state, show) => {
    state.isDetailModalOpen = show
  },
  [types.GET_THONGBAOTOCHUC]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.GET_THONGBAOTOCHUC_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.GET_THONGBAOTOCHUC_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.APPROVE_THONGBAOTOCHUC]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.APPROVE_THONGBAOTOCHUC_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.APPROVE_THONGBAOTOCHUC_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.GET_YKIENPHEDUYET]: state => {
    state.isGetting = true
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYET_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = response.obj
  },
  [types.GET_YKIENPHEDUYET_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: state => {
    state.isGetting = true
    state.yKienPheDuyetGanNhat = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = response.obj
  },
  [types.GET_YKIENPHEDUYETGANNHAT_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = {}
  }
}
