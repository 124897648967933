import * as types from './actionTypes'

export default {
  [types.GET_LIST_APPLICATION_USER_PAGING]: state => {
    state.isFetchingList = true
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_APPLICATION_USER_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingList = false
    state.dataSourcePaging = Object.freeze(data)
  },
  [types.GET_LIST_APPLICATION_USER_PAGING_FAILED]: (state, err) => {
    state.isFetchingList = false
    state.responseResult = err
    state.dataSourcePaging = {}
  },
  [types.GET_APPLICATION_USER]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.GET_APPLICATION_USER_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.GET_APPLICATION_USER_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.CREATE_APPLICATION_USER]: state => {
    state.isSaving = true
  },
  [types.CREATE_APPLICATION_USER_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_APPLICATION_USER_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_APPLICATION_USER]: state => {
    state.isSaving = true
  },
  [types.UPDATE_APPLICATION_USER_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_APPLICATION_USER_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CHANGEPASSWORD_APPLICATION_USER]: state => {
    state.isChanging = true
  },
  [types.CHANGEPASSWORD_APPLICATION_USER_SUCCESS]: (state, response) => {
    state.isChanging = false
    state.responseResult = response
  },
  [types.CHANGEPASSWORD_APPLICATION_USER_FAILED]: (state, response) => {
    state.isChanging = false
    state.responseResult = response
  },
  [types.DELETE_APPLICATION_USER]: state => {
    state.isDeleting = true
  },
  [types.DELETE_APPLICATION_USER_SUCCESS]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.DELETE_APPLICATION_USER_FAILED]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  }
}
