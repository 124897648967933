import * as types from './actionTypes'
import jwtInterceptor from '@/shared/jwtInterceptor'
import appConfig from '@/shared/appConfig'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/DeXuatCTXTDT_BoNganh`

export const actions = {
  [types.GET_LIST_DEXUATCTXTDT_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_DEXUATCTXTDT_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/Filter`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_DEXUATCTXTDT_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_DEXUATCTXTDT_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/FilterPheDuyet`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_UPDATE_MODAL, payload)
  },
  [types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL, payload)
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (context, value) => {
    context.commit(types.TOGGLE_DELETE_CONFIRMATION_MODAL, value)
  },
  [types.CREATE_DEXUATCTXTDT]: async (context, payload) => {
    context.commit(types.CREATE_DEXUATCTXTDT)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/`, payload).catch(error => {
      context.commit(types.CREATE_DEXUATCTXTDT_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_DEXUATCTXTDT_FAILED, response.data)
      } else {
        context.commit(types.CREATE_DEXUATCTXTDT_SUCCESS, response.data)
      }
    }
  },
  [types.CREATE_CTXTDTPHULUCCUABOUBNDTINH]: async (context, payload) => {
    context.commit(types.CREATE_CTXTDTPHULUCCUABOUBNDTINH)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.deXuatCTXTDTGuid}/chitiet`, payload).catch(error => {
      context.commit(types.CREATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED, response.data)
      } else {
        context.commit(types.CREATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_DEXUATCTXTDT]: async (context, payload) => {
    context.commit(types.UPDATE_DEXUATCTXTDT)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}`, payload).catch(error => {
      context.commit(types.UPDATE_DEXUATCTXTDT_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_DEXUATCTXTDT_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_DEXUATCTXTDT_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH]: async (context, payload) => {
    context.commit(types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH)
    console.log(payload)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}/chitiet`, payload).catch(error => {
      context.commit(types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS, response.data)
      }
    }
  },
  [types.DIEUCHINH_DEXUATCTXTDT]: async (context, payload) => {
    context.commit(types.DIEUCHINH_DEXUATCTXTDT)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}/dieuchinh`, payload).catch(error => {
      context.commit(types.DIEUCHINH_DEXUATCTXTDT_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DIEUCHINH_DEXUATCTXTDT_FAILED, response.data)
      } else {
        context.commit(types.DIEUCHINH_DEXUATCTXTDT_SUCCESS, response.data)
      }
    }
  },
  [types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH]: async (context, payload) => {
    context.commit(types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH)
    console.log(payload)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}/chitiet/dieuchinh`, payload).catch(error => {
      context.commit(types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_FAILED, response.data)
      } else {
        context.commit(types.DIEUCHINH_CTXTDTPHULUCCUABOUBNDTINH_SUCCESS, response.data)
      }
    }
  },
  [types.DELETE_DEXUATCTXTDT]: async (context, payload) => {
    context.commit(types.DELETE_DEXUATCTXTDT)
    const response = await jwtInterceptor.delete(`${BASE_ENDPOINT}/${payload.id}`).catch(error => {
      context.commit(types.DELETE_DEXUATCTXTDT_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DELETE_DEXUATCTXTDT_FAILED, response.data)
      } else {
        context.commit(types.DELETE_DEXUATCTXTDT_SUCCESS, response.data)
      }
    }
  },
  [types.GET_DEXUATCTXTDT]: async (context, id) => {
    context.commit(types.GET_DEXUATCTXTDT)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_DEXUATCTXTDT_SUCCESS, response.data)
    } else {
      context.commit(types.GET_DEXUATCTXTDT_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_DETAIL_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_DETAIL_MODAL, payload)
  },
  [types.TOGGLE_UPDATE_PHULUC_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_UPDATE_PHULUC_MODAL, payload)
  },
  [types.APPROVE_DEXUATCTXTDT]: async (context, payload) => {
    context.commit(types.APPROVE_DEXUATCTXTDT)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.id}/PheDuyet`, payload).catch(error => {
      context.commit(types.APPROVE_DEXUATCTXTDT_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.APPROVE_DEXUATCTXTDT_FAILED, response.data)
      } else {
        context.commit(types.APPROVE_DEXUATCTXTDT_SUCCESS, response.data)
      }
    }
  },
  [types.GET_YKIENPHEDUYET]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYET)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyet`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYET_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYET_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYETGANNHAT)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyetgannhat`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_TONGHOPDEXUAT1121]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1121)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1121`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1121_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1121_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1121_SUCCESS, response.data)
      }
    }
  },
  [types.GET_TONGHOPDEXUAT1122]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1122)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1122`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1122_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1122_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1122_SUCCESS, response.data)
      }
    }
  },
  [types.GET_TONGHOPDEXUAT1124]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1124)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1124`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1124_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1124_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1124_SUCCESS, response.data)
      }
    }
  },
  [types.GET_TONGHOPDEXUAT1122]: async (context, payload) => {
    context.commit(types.GET_TONGHOPDEXUAT1122)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/TongHopDeXuat_1122`, payload).catch(error => {
      context.commit(types.GET_TONGHOPDEXUAT1122_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.GET_TONGHOPDEXUAT1122_FAILED, response.data)
      } else {
        context.commit(types.GET_TONGHOPDEXUAT1122_SUCCESS, response.data)
      }
    }
  },
  [types.GET_HISTORY]: async (context, id) => {
    context.commit(types.GET_HISTORY)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORY_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORY_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_HISTORYDETAIL]: async (context, id) => {
    context.commit(types.GET_HISTORYDETAIL)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/chitiet/history`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_HISTORYDETAIL_SUCCESS, response.data)
    } else {
      context.commit(types.GET_HISTORYDETAIL_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_HISTORY_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_HISTORY_MODAL, payload)
  },
  [types.TOGGLE_HISTORYDETAIL_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_HISTORYDETAIL_MODAL, payload)
  }
}
