import * as types from './actionTypes'
import jwtInterceptor from '@/shared/jwtInterceptor'
import appConfig from '@/shared/appConfig'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/DmLoaiHoatDong`

export const actions = {
  [types.GET_LIST_LOAIHOATDONG_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_LOAIHOATDONG_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/Filter`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_LOAIHOATDONG_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_LOAIHOATDONG_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (context, payload) => {
    context.commit(types.TOGGLE_CREATE_UPDATE_MODAL, payload)
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (context, value) => {
    context.commit(types.TOGGLE_DELETE_CONFIRMATION_MODAL, value)
  },
  [types.CREATE_LOAIHOATDONG]: async (context, payload) => {
    context.commit(types.CREATE_LOAIHOATDONG)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/`, payload).catch(error => {
      context.commit(types.CREATE_LOAIHOATDONG_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_LOAIHOATDONG_FAILED, response.data)
      } else {
        context.commit(types.CREATE_LOAIHOATDONG_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_LOAIHOATDONG]: async (context, payload) => {
    context.commit(types.UPDATE_LOAIHOATDONG)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}`, payload).catch(error => {
      context.commit(types.UPDATE_LOAIHOATDONG_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_LOAIHOATDONG_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_LOAIHOATDONG_SUCCESS, response.data)
      }
    }
  },
  [types.DELETE_LOAIHOATDONG]: async (context, payload) => {
    context.commit(types.DELETE_LOAIHOATDONG)
    const response = await jwtInterceptor.delete(`${BASE_ENDPOINT}/${payload.id}`).catch(error => {
      context.commit(types.DELETE_LOAIHOATDONG_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DELETE_LOAIHOATDONG_FAILED, response.data)
      } else {
        context.commit(types.DELETE_LOAIHOATDONG_SUCCESS, response.data)
      }
    }
  },
  [types.TOGGLE_DETAIL_MODAL]: async (context, {
    show,
    item
  }) => {
    context.commit(types.TOGGLE_DETAIL_MODAL, show)
    if (show && item.id) {
      context.commit(types.GET_LOAIHOATDONG)
      const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${item.id}`).catch(error => {
        context.commit(types.GET_LOAIHOATDONG_FAILED, {
          error: true,
          message: `${error.response.status} ${error.response.statusText}`
        })
      })
      if (response) {
        if (response.data.error) {
          context.commit(types.GET_LOAIHOATDONG_FAILED, response.data)
        } else {
          context.commit(types.GET_LOAIHOATDONG_SUCCESS, response.data)
        }
      }
    }
  }
}
