import { extend } from 'vee-validate'
import { required, email, confirmed } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: '{_field_} không được bỏ trống'
})

extend('email', {
  ...email,
  message: '{_field_} không đúng định dạng'
})

extend('confirmed', {
  ...confirmed,
  message: '{_field_} không khớp'
})
