import * as types from './actionTypes'

export default {
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING]: state => {
    state.isFetchingList = true
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingList = false
    state.dataSourcePaging = Object.freeze(data)
  },
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_FAILED]: (state, err) => {
    state.isFetchingList = false
    state.responseResult = err
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING]: state => {
    state.isFetchingListDieuChinh = true
    state.dataSourcePagingDieuChinh = {}
  },
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingListDieuChinh = false
    state.dataSourcePagingDieuChinh = Object.freeze(data)
  },
  [types.GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_FAILED]: (state, err) => {
    state.isFetchingListDieuChinh = false
    state.responseResult = err
    state.dataSourcePagingDieuChinh = {}
  },
  [types.TOGGLE_CREATE_UPDATE_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.editedItem = Object.assign({}, item)
    state.isCreateOrUpdateModalOpen = show
  },
  [types.TOGGLE_CREATE_PHULUC_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.editedItem = Object.assign({}, item)
    state.isCreatePhuLucModalOpen = show
  },
  [types.TOGGLE_CREATE_DEAN_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.editedItem = Object.assign({}, item)
    state.isCreateDeAnModalOpen = show
  },
  // [types.TOGGLE_DETAIL_DEAN_MODAL]: (state, {
  //   show,
  //   item = {}
  // }) => {
  //   state.detailItem = Object.assign({}, item)
  //   // state.deAnItem = Object.assign({}, item)
  //   state.isDetailDeAnModalOpen = show
  // },
  [types.TOGGLE_DETAIL_DEAN_MODAL]: (state, {
    show
  }) => {
    state.isDetailDeAnModalOpen = show
  },
  [types.TOGGLE_UPDATE_DEAN_MODAL]: (state, {
    show
  }) => {
    state.isUpdateDeAnModalOpen = show
  },
  [types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.phulucItem = Object.assign({}, item)
    state.isCreateOrUpdatePhuLucModalOpen = show
  },
  [types.CREATE_DEXUATCTXTDTQUOCGIA]: state => {
    state.isSaving = true
    state.editedItem = {}
  },
  [types.CREATE_DEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_DEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_CTXTDTQGPHULUC]: state => {
    state.isSavingPhuLuc = true
    state.phuLucItem = {}
  },
  [types.CREATE_CTXTDTQGPHULUC_SUCCESS]: (state, response) => {
    state.isSavingPhuLuc = false
    state.responseResult = response
  },
  [types.CREATE_CTXTDTQGPHULUC_FAILED]: (state, response) => {
    state.isSavingPhuLuc = false
    state.responseResult = response
  },
  [types.CREATE_DEANDEXUATCTXTDTQUOCGIA]: state => {
    state.isSavingDeAn = true
    state.editedItem = {}
  },
  [types.CREATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isSavingDeAn = false
    state.responseResult = response
  },
  [types.CREATE_DEANDEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isSavingDeAn = false
    state.responseResult = response
  },
  [types.UPDATE_DEXUATCTXTDTQUOCGIA]: state => {
    state.isSaving = true
  },
  [types.UPDATE_DEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.UPDATE_DEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_CTXTDTQGPHULUC]: state => {
    state.isSaving = true
  },
  [types.UPDATE_CTXTDTQGPHULUC_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.UPDATE_CTXTDTQGPHULUC_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_DEANDEXUATCTXTDTQUOCGIA]: state => {
    state.isSaving = true
  },
  [types.UPDATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.UPDATE_DEANDEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.DIEUCHINH_DEXUATCTXTDTQUOCGIA]: state => {
    state.isSaving2 = true
  },
  [types.DIEUCHINH_DEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isSaving2 = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.DIEUCHINH_DEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isSaving2 = false
    state.responseResult = response
  },
  [types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA]: state => {
    state.isSavingDeAn2 = true
  },
  [types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isSavingDeAn2 = false
    state.responseResult = response
    state.editedItem = {}
  },
  [types.DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isSavingDeAn2 = false
    state.responseResult = response
  },
  [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.deletedItem = Object.assign({}, item)
    state.isDeleteConfirmationModalOpen = show
  },
  [types.DELETE_DEXUATCTXTDTQUOCGIA]: state => {
    state.isDeleting = true
  },
  [types.DELETE_DEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
    state.deletedItem = {}
  },
  [types.DELETE_DEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.TOGGLE_DETAIL_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.phulucItem = Object.assign({}, item)
    state.isPhuLucDetailModalOpen = show
  },
  [types.TOGGLE_UPDATE_PHULUC_MODAL]: (state, {
    show,
    item = {}
  }) => {
    state.phulucItem = Object.assign({}, item)
    state.isPhuLucUpdateModalOpen = show
  },
  [types.GET_DEXUATCTXTDTQUOCGIA]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.GET_DEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.GET_DEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.APPROVE_DEXUATCTXTDTQUOCGIA]: state => {
    state.isSaving = true
  },
  [types.APPROVE_DEXUATCTXTDTQUOCGIA_SUCCESS]: (state, response) => {
    state.isProving = false
    state.responseResult = response
  },
  [types.APPROVE_DEXUATCTXTDTQUOCGIA_FAILED]: (state, response) => {
    state.isProving = false
    state.responseResult = response
  },
  [types.GET_YKIENPHEDUYET]: state => {
    state.isGetting = true
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYET_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = response.obj
  },
  [types.GET_YKIENPHEDUYET_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyet = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: state => {
    state.isGetting = true
    state.yKienPheDuyetGanNhat = {}
  },
  [types.GET_YKIENPHEDUYETGANNHAT_SUCCESS]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = response.obj
  },
  [types.GET_YKIENPHEDUYETGANNHAT_FAILED]: (state, response) => {
    state.isGetting = false
    state.responseResult = response
    state.yKienPheDuyetGanNhat = {}
  },
  [types.GET_TONGHOPDEXUAT1111]: state => {
    state.tongHopDeXuat1111 = {}
  },
  [types.GET_TONGHOPDEXUAT1111_SUCCESS]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1111 = response.obj
  },
  [types.GET_TONGHOPDEXUAT1111_FAILED]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1111 = {}
  },
  [types.GET_TONGHOPDEXUAT1112]: state => {
    state.tongHopDeXuat1112 = {}
  },
  [types.GET_TONGHOPDEXUAT1112_SUCCESS]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1112 = response.obj
  },
  [types.GET_TONGHOPDEXUAT1112_FAILED]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1112 = {}
  },
  [types.GET_TONGHOPDEXUAT1114]: state => {
    state.tongHopDeXuat1114 = {}
  },
  [types.GET_TONGHOPDEXUAT1114_SUCCESS]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1114 = response.obj
  },
  [types.GET_TONGHOPDEXUAT1114_FAILED]: (state, response) => {
    state.responseResult = response
    state.tongHopDeXuat1114 = {}
  },
  [types.GET_HISTORY]: state => {
    state.historyData = {}
  },
  [types.GET_HISTORY_SUCCESS]: (state, response) => {
    state.historyData = response.obj
  },
  [types.GET_HISTORY_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyData = {}
  },
  [types.GET_HISTORYDETAIL]: state => {
    state.historyDetail = {}
  },
  [types.GET_HISTORYDETAIL_SUCCESS]: (state, response) => {
    state.historyDetail = response.obj
  },
  [types.GET_HISTORYDETAIL_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyDetail = {}
  },
  [types.GET_HISTORYDEAN]: state => {
    state.historyDeAn = {}
  },
  [types.GET_HISTORYDEAN_SUCCESS]: (state, response) => {
    state.historyDeAn = response.obj
  },
  [types.GET_HISTORYDEAN_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyDeAn = {}
  },
  [types.GET_HISTORYHANGMUCCONGVIEC]: state => {
    state.historyHangMucCongViec = {}
  },
  [types.GET_HISTORYHANGMUCCONGVIEC_SUCCESS]: (state, response) => {
    state.historyHangMucCongViec = response.obj
  },
  [types.GET_HISTORYHANGMUCCONGVIEC_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyHangMucCongViec = {}
  },
  [types.GET_HISTORYDUTOANKINHPHI]: state => {
    state.historyDuToanKinhPHi = {}
  },
  [types.GET_HISTORYDUTOANKINHPHI_SUCCESS]: (state, response) => {
    state.historyDuToanKinhPHi = response.obj
  },
  [types.GET_HISTORYDUTOANKINHPHI_FAILED]: (state, err) => {
    state.responseResult = err
    state.historyDuToanKinhPHi = {}
  }
}
