import * as types from './actionTypes'

export default {
  [types.GET_LIST_TINHTHANHPHO_PAGING]: state => {
    state.isFetchingList = true
    state.dataSourcePaging = {}
  },
  [types.GET_LIST_TINHTHANHPHO_PAGING_SUCCESS]: (state, data) => {
    state.isFetchingList = false
    state.dataSourcePaging = Object.freeze(data)
  },
  [types.GET_LIST_TINHTHANHPHO_PAGING_FAILED]: (state, err) => {
    state.isFetchingList = false
    state.responseResult = err
    state.dataSourcePaging = {}
  },
  [types.GET_TINHTHANHPHO]: state => {
    state.isFetchingSingle = true
    state.detailItem = {}
  },
  [types.GET_TINHTHANHPHO_SUCCESS]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = response.obj
  },
  [types.GET_TINHTHANHPHO_FAILED]: (state, response) => {
    state.isFetchingSingle = false
    state.responseResult = response
    state.detailItem = {}
  },
  [types.CREATE_TINHTHANHPHO]: state => {
    state.isSaving = true
  },
  [types.CREATE_TINHTHANHPHO_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.CREATE_TINHTHANHPHO_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_TINHTHANHPHO]: state => {
    state.isSaving = true
  },
  [types.UPDATE_TINHTHANHPHO_SUCCESS]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.UPDATE_TINHTHANHPHO_FAILED]: (state, response) => {
    state.isSaving = false
    state.responseResult = response
  },
  [types.DELETE_TINHTHANHPHO]: state => {
    state.isDeleting = true
  },
  [types.DELETE_TINHTHANHPHO_SUCCESS]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  },
  [types.DELETE_TINHTHANHPHO_FAILED]: (state, response) => {
    state.isDeleting = false
    state.responseResult = response
  }
}
