export const GET_LIST_THONGBAOTOCHUC_PAGING = 'GET_LIST_THONGBAOTOCHUC_PAGING'
export const GET_LIST_THONGBAOTOCHUC_PAGING_SUCCESS = 'GET_LIST_THONGBAOTOCHUC_PAGING_SUCCESS'
export const GET_LIST_THONGBAOTOCHUC_PAGING_FAILED = 'GET_LIST_THONGBAOTOCHUC_PAGING_FAILED'

export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL'
export const GET_THONGBAOTOCHUC = 'GET_THONGBAOTOCHUC'
export const GET_THONGBAOTOCHUC_SUCCESS = 'GET_THONGBAOTOCHUC_SUCCESS'
export const GET_THONGBAOTOCHUC_FAILED = 'GET_THONGBAOTOCHUC_FAILED'

export const TOGGLE_CREATE_UPDATE_MODAL = 'TOGGLE_CREATE_UPDATE_MODAL'
export const TOGGLE_CREATE_MODAL = 'TOGGLE_CREATE_MODAL'

export const CREATE_THONGBAOTOCHUC = 'CREATE_THONGBAOTOCHUC'
export const CREATE_THONGBAOTOCHUC_SUCCESS = 'CREATE_THONGBAOTOCHUC_SUCCESS'
export const CREATE_THONGBAOTOCHUC_FAILED = 'CREATE_THONGBAOTOCHUC_FAILED'

export const UPDATE_THONGBAOTOCHUC = 'UPDATE_THONGBAOTOCHUC'
export const UPDATE_THONGBAOTOCHUC_SUCCESS = 'UPDATE_THONGBAOTOCHUC_SUCCESS'
export const UPDATE_THONGBAOTOCHUC_FAILED = 'UPDATE_THONGBAOTOCHUC_FAILED'

export const TOGGLE_DELETE_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CONFIRMATION_MODAL'
export const DELETE_THONGBAOTOCHUC = 'DELETE_THONGBAOTOCHUC'
export const DELETE_THONGBAOTOCHUC_SUCCESS = 'DELETE_THONGBAOTOCHUC_SUCCESS'
export const DELETE_THONGBAOTOCHUC_FAILED = 'DELETE_THONGBAOTOCHUC_FAILED'

export const APPROVE_THONGBAOTOCHUC = 'APPROVE_THONGBAOTOCHUC'
export const APPROVE_THONGBAOTOCHUC_SUCCESS = 'APPROVE_THONGBAOTOCHUC_SUCCESS'
export const APPROVE_THONGBAOTOCHUC_FAILED = 'APPROVE_THONGBAOTOCHUC_FAILED'

export const GET_YKIENPHEDUYET = 'GET_YKIENPHEDUYET'
export const GET_YKIENPHEDUYET_SUCCESS = 'GET_YKIENPHEDUYET_SUCCESS'
export const GET_YKIENPHEDUYET_FAILED = 'GET_YKIENPHEDUYET_FAILED'

export const GET_YKIENPHEDUYETGANNHAT = 'GET_YKIENPHEDUYETGANNHAT'
export const GET_YKIENPHEDUYETGANNHAT_SUCCESS = 'GET_YKIENPHEDUYETGANNHAT_SUCCESS'
export const GET_YKIENPHEDUYETGANNHAT_FAILED = 'GET_YKIENPHEDUYETGANNHAT_FAILED'
