import * as types from './actionTypes'
import jwtInterceptor from '@/shared/jwtInterceptor'
import appConfig from '@/shared/appConfig'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/DmDonVi`

export const actions = {
  [types.GET_LIST_DONVI_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_DONVI_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/Filter`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_DONVI_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_DONVI_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_DONVI]: async (context, id) => {
    context.commit(types.GET_DONVI)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_DONVI_SUCCESS, response.data)
    } else {
      context.commit(types.GET_DONVI_FAILED, response.data.message || response.statusText)
    }
  },
  [types.CREATE_DONVI]: async (context, payload) => {
    context.commit(types.CREATE_DONVI)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/`, payload).catch(error => {
      context.commit(types.CREATE_DONVI_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_DONVI_FAILED, response.data)
      } else {
        context.commit(types.CREATE_DONVI_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_DONVI]: async (context, payload) => {
    context.commit(types.UPDATE_DONVI)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}`, payload).catch(error => {
      context.commit(types.UPDATE_DONVI_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_DONVI_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_DONVI_SUCCESS, response.data)
      }
    }
  },
  [types.DELETE_DONVI]: async (context, payload) => {
    context.commit(types.DELETE_DONVI)
    const response = await jwtInterceptor.delete(`${BASE_ENDPOINT}/${payload.id}`).catch(error => {
      context.commit(types.DELETE_DONVI_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DELETE_DONVI_FAILED, response.data)
      } else {
        context.commit(types.DELETE_DONVI_SUCCESS, response.data)
      }
    }
  }
}
