<template>
  <div class="file-upload">
<!--    style="border: 1px double"-->
    <div class="import-files mb-2" v-if="uploadedFiles.length">
      <div class="files mb-1" v-for="(file, index) in uploadedFiles" v-bind:key="file.id">
        <a :href="getDownloadURL(file.url)" target="_blank">{{file.fileName}}</a>
        <c-button-close class="cursor-pointer text-danger fs-14 ml-1" @click="removeFile(file.fileName, index)"></c-button-close>
      </div>
    </div>
    <file-pond
        name="upload"
        ref="pond"
        :label-idle="labelIdle"
        :allow-multiple="true"
        :accepted-file-types="allowedFileTypes"
        :files="uploadedFile"
        @init="handleFilePondInit"
        :credits="``"
        :server="{ process, revert, restore, load, fetch }"
        labelFileProcessingComplete="OK"
        labelTapToUndo=""
        labelFileProcessing="..."
        labelTapToRetry="Thử lại"
        labelTapToCancel="Hủy"
        labelFileProcessingError="Có lỗi xảy ra"
        :class="customClasses"
    />
<!--    <p class="">Dung lượng mỗi file không quá </p>-->
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import axios from 'axios'
import appConfig from '@/shared/appConfig'
// import jwtInterceptor from '@/shared/jwtInterceptor'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

// const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/upload`

export default {
  name: 'FileUpload',
  props: {
    attachments: {
      type: Array,
      default () {
        return []
      }
    },
    labelIdle: {
      type: String,
      default: 'Chọn hoặc kéo thả files...'
    },
    customClasses: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    FilePond
  },
  data () {
    return {
      allowedFileTypes: this.getFileUploadAllowType(),
      uploadedFile: [],
      item: {
        ListFileAttachAdd: [],
        listValueFileAttachRemove: ''
      },
      uploadedFiles: []
    }
  },
  methods: {
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
      // return url
    },
    getFileUploadAllowType () {
      return 'image/jpg, image/jpeg, image/png,' +
        'application/pdf, application/msword,' +
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
        'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
        'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation'
    },
    handleFilePondInit: function () {

    },
    async process (fieldName, file, metadata, load, error, progress, abort, transfer, options) {
      const isExistedFile = this.item.ListFileAttachAdd.findIndex(x => x.FileName === file.name)

      if (isExistedFile === -1) {
        if (this.isValidFileName(file.name)) {
          const formData = new FormData()
          formData.append('path', file, file.name)

          // const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/{type}`, formData)
          // if (response && response.data && !response.data.error) {
          //
          // } else {
          //
          // }

          axios({
            method: 'post',
            url: `${appConfig.BASE_HOST}/api/upload/${this.type}`,
            data: formData,
            onUploadProgress: (e) => {
              progress(e.lengthComputable, e.loaded, e.total)
            }
          }).then(response => {
            if (response.data.obj) {
              const id = Math.round(Math.random() * 100000)
              load(id)
              this.item.ListFileAttachAdd.push({
                ID: id,
                // FileServer: response.data.fileserver,
                url: response.data.obj.url,
                fileName: response.data.obj.fileName
              })
            } else {
              abort(response)
            }
          }).catch((thrown) => {
            console.log(thrown)
          })
        } else {
          this.$toast.error('Tên file không được chứa ký tự ! @ # $ % ^ & * ( ) \\ | , . < > ? / ` ~')
          error('error')
        }
      } else {
        this.$toast.error(`Tên file ${file.name} đã được tải lên.\nVui lòng đổi tên file và thao tác lại!`)
        return error('error')
      }

      return {
        abort () {
          abort()
        }
      }
    },
    revert (uniqueFileId, load, error) {
      let removeItems = `${this.item.listValueFileAttachRemove}`
      const index = this.item.ListFileAttachAdd.findIndex(x => x.ID === uniqueFileId)
      if (index > -1) {
        removeItems += removeItems ? `#${this.item.ListFileAttachAdd[index].url}` : `${this.item.ListFileAttachAdd[index].url}`
        this.item.listValueFileAttachRemove = removeItems
        this.item.ListFileAttachAdd.splice(index, 1)
      }
      load()
    },
    load (uniqueFileId, load, error) { error() },
    fetch (url, load, error, progress, abort, headers) { error('error') },
    restore (uniqueFileId, load, error, progress, abort, headers) { error() },
    removeFile (fileName, index) {
      console.log(fileName, index)
      let removeItems = `${this.item.listValueFileAttachRemove}`
      if (fileName) {
        removeItems += removeItems ? `#${fileName}` : `${fileName}`
        this.item.listValueFileAttachRemove = removeItems
      }
      this.item.ListFileAttachAdd.splice(index, 1)
      this.uploadedFiles.splice(index, 1)
    },
    clearFiles () {
      this.item = {
        ...this.item,
        ListFileAttachAdd: [],
        listValueFileAttachRemove: ''
      }
      this.$refs.pond.removeFiles()
    },
    isValidFileName (fileName) {
      // const pattern = /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\""|\;|\:|\t|\r|\n/g
      // return !fileName.match(pattern)
      return true
    }
  },
  watch: {
    'item.ListFileAttachAdd': function (value) {
      this.$emit('onChange', this.item)
    },
    'item.listValueFileAttachRemove': function (value) {
      this.$emit('onChange', this.item)
    },
    attachments: function (value) {
      this.uploadedFiles = value
      this.item.ListFileAttachAdd = value
    }
  }
}
</script>

<style scoped>

</style>
