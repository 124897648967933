const nguonKinhPhi = [
  {
    label: '--Chọn--',
    value: 0
  },
  {
    label: 'Ngân sách địa phương',
    value: 1
  },
  {
    label: 'Tài trợ',
    value: 2
  }
]

const trangThaiDeXuatCTXTDT = [
  {
    label: 'Tất cả',
    value: 0
  },
  {
    label: 'Soạn thảo',
    value: 1
  },
  {
    label: 'Chờ phê duyệt',
    value: 2
  },
  {
    label: 'Đã phê duyệt',
    value: 3
  },
  {
    label: 'Yêu cầu hiệu chỉnh',
    value: 4
  }
]

const loaiPheDuyetDeXuatCTXTDT = [
  {
    label: '--Chọn--',
    value: 0
  },
  {
    label: 'Phê Duyệt',
    value: 1
  },
  {
    label: 'Yêu cầu hiệu chỉnh',
    value: 2
  }
]

const loaiDonVi = [
  {
    label: 'MPI',
    value: 1
  },
  {
    label: 'Bộ/ Cơ quan ngang Bộ',
    value: 2
  },
  {
    label: 'UBND cấp Tỉnh',
    value: 3
  },
  {
    label: 'Quản trị hệ thống',
    value: 4
  }
]
const loaiHoatDongDeXuatCTXTDT = [
  {
    label: '--Chọn--',
    value: 0
  },
  {
    label: 'Nghiên cứu tiềm năng, thị trường, xu hướng và đối tác đầu tư.',
    value: 1
  },
  {
    label: 'Xây dựng hình ảnh, tuyên truyền, quảng bá, giới thiệu về môi trường, chính sách, tiềm năng, cơ hội và kết nối đầu tư.',
    value: 2
  },
  {
    label: 'Hỗ trợ, hướng dẫn, tạo thuận lợi cho hoạt động đầu tư.',
    value: 3
  },
  {
    label: 'Xây dựng hệ thống thông tin và cơ sở dữ liệu phục vụ cho hoạt động xúc tiến đầu tư.',
    value: 4
  },
  {
    label: 'Xây dựng danh mục dự án thu hút đầu tư.',
    value: 5
  },
  {
    label: 'Xây dựng các ấn phẩm, tài liệu phục vụ cho hoạt động xúc tiến đầu tư.',
    value: 6
  },
  {
    label: 'Đào tạo, tập huấn, tăng cường năng lực về xúc tiến đầu tư.',
    value: 7
  },
  {
    label: 'Hợp tác trong nước và quốc tế về xúc tiến đầu tư.',
    value: 8
  }
]

const donViChuTriDeXuatCTXTDT = [
  {
    label: '--Chọn--',
    value: ''
  },
  {
    label: 'Ủy ban nhân dân tỉnh/thành phố',
    value: 'Ủy ban nhân dân tỉnh/thành phố'
  },
  {
    label: 'Sở Kế hoạch và Đầu tư',
    value: 'Sở Kế hoạch và Đầu tư'
  },
  {
    label: 'Ban quản lý KCN/KCX/KKT',
    value: 'Ban quản lý KCN/KCX/KKT'
  },
  {
    label: 'Trung tâm XTĐT',
    value: 'Trung tâm XTĐT'
  }
  // {
  //   label: 'Khác (ghi rõ)',
  //   value: 'Khác (ghi rõ)'
  // }
]

const diaBanKeuGoiDauTuDeXuatCTXTDT = [
  {
    label: '--Chọn--',
    value: null
  },
  {
    label: 'Đồng bằng sông Hồng',
    value: 'Đồng bằng sông Hồng'
  },
  {
    label: 'Trung du và miền núi phía Bắc',
    value: 'Trung du và miền núi phía Bắc'
  },
  {
    label: 'Bắc Trung Bộ và duyên hải miền Trung',
    value: 'Bắc Trung Bộ và duyên hải miền Trung'
  },
  {
    label: 'Tây Nguyên',
    value: 'Tây Nguyên'
  },
  {
    label: 'Đông Nam Bộ',
    value: 'Đông Nam Bộ'
  },
  {
    label: 'Đồng bằng sông Cửu Long',
    value: 'Đồng bằng sông Cửu Long'
  }
]
export const enums = {
  nguonKinhPhi,
  trangThaiDeXuatCTXTDT,
  loaiPheDuyetDeXuatCTXTDT,
  loaiDonVi,
  donViChuTriDeXuatCTXTDT,
  diaBanKeuGoiDauTuDeXuatCTXTDT,
  loaiHoatDongDeXuatCTXTDT
}
