export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  isFetchingListDieuChinh: state => state.isFetchingListDieuChinh,
  dataSourcePagingDieuChinh: state => state.dataSourcePagingDieuChinh,
  editedItem: state => state.editedItem,
  phulucItem: state => state.phulucItem,
  responseResult: state => state.responseResult,
  formTitle: state => state.editedItem.id ? 'Sửa CT XTĐT của Bộ, cơ quan ngang Bộ' : 'Thêm CT XTĐT của Bộ, cơ quan ngang Bộ',
  formPhuLucTitle: state => state.phulucItem.id ? 'Sửa phụ lục CT XTĐT của Bộ, cơ quan ngang Bộ' : 'Thêm phụ lục CT XTĐT của Bộ, cơ quan ngang Bộ',
  isSaving: state => state.isSaving,
  isSaving2: state => state.isSaving2,
  isCreateOrUpdateModalOpen: state => state.isCreateOrUpdateModalOpen,
  isCreateOrUpdatePhuLucModalOpen: state => state.isCreateOrUpdatePhuLucModalOpen,
  isDeleting: state => state.isDeleting,
  deletedItem: state => state.deletedItem,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  detailItem: state => state.detailItem,
  isDetailModalOpen: state => state.isDetailModalOpen,
  isPhuLucDetailModalOpen: state => state.isPhuLucDetailModalOpen,
  isPhuLucUpdateModalOpen: state => state.isPhuLucUpdateModalOpen,
  yKienPheDuyet: state => state.yKienPheDuyet,
  yKienPheDuyetGanNhat: state => state.yKienPheDuyetGanNhat,
  tongHopDeXuat1121: state => state.tongHopDeXuat1121,
  tongHopDeXuat1122: state => state.tongHopDeXuat1122,
  tongHopDeXuat1124: state => state.tongHopDeXuat1124,
  historyData: state => state.historyData,
  isHistoryModalOpen: state => state.isHistoryModalOpen,
  historyDetail: state => state.historyDetail,
  isHistoryDetailModalOpen: state => state.isHistoryDetailModalOpen,
  isFetchingSingle: state => state.isFetchingSingle,
  isApproving: state => state.isApproving,
  isGetting: state => state.isGetting
}
