import * as types from './actionTypes'
import jwtInterceptor from '@/shared/jwtInterceptor'
import appConfig from '@/shared/appConfig'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/VanBanTBKHTCHDXTHHDTTMDLNGKT`

export const actions = {
  [types.GET_LIST_THONGBAOKEHOACH_PAGING]: async (context, payload) => {
    context.commit(types.GET_LIST_THONGBAOKEHOACH_PAGING)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/Filter`, payload)
    // const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/SearchVanBan`, payload)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_LIST_THONGBAOKEHOACH_PAGING_SUCCESS, response.data.obj)
    } else {
      context.commit(types.GET_LIST_THONGBAOKEHOACH_PAGING_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_THONGBAOKEHOACH]: async (context, id) => {
    context.commit(types.GET_THONGBAOKEHOACH)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_THONGBAOKEHOACH_SUCCESS, response.data)
    } else {
      context.commit(types.GET_THONGBAOKEHOACH_FAILED, response.data.message || response.statusText)
    }
  },
  [types.CREATE_THONGBAOKEHOACH]: async (context, payload) => {
    context.commit(types.CREATE_THONGBAOKEHOACH)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/`, payload).catch(error => {
      context.commit(types.CREATE_THONGBAOKEHOACH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.CREATE_THONGBAOKEHOACH_FAILED, response.data)
      } else {
        context.commit(types.CREATE_THONGBAOKEHOACH_SUCCESS, response.data)
      }
    }
  },
  [types.UPDATE_THONGBAOKEHOACH]: async (context, payload) => {
    context.commit(types.UPDATE_THONGBAOKEHOACH)
    const response = await jwtInterceptor.put(`${BASE_ENDPOINT}/${payload.id}`, payload).catch(error => {
      context.commit(types.UPDATE_THONGBAOKEHOACH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.UPDATE_THONGBAOKEHOACH_FAILED, response.data)
      } else {
        context.commit(types.UPDATE_THONGBAOKEHOACH_SUCCESS, response.data)
      }
    }
  },
  [types.DELETE_THONGBAOKEHOACH]: async (context, payload) => {
    context.commit(types.DELETE_THONGBAOKEHOACH)
    const response = await jwtInterceptor.delete(`${BASE_ENDPOINT}/${payload.id}`).catch(error => {
      context.commit(types.DELETE_THONGBAOKEHOACH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.DELETE_THONGBAOKEHOACH_FAILED, response.data)
      } else {
        context.commit(types.DELETE_THONGBAOKEHOACH_SUCCESS, response.data)
      }
    }
  },
  [types.APPROVE_THONGBAOKEHOACH]: async (context, payload) => {
    context.commit(types.APPROVE_THONGBAOKEHOACH)
    const response = await jwtInterceptor.post(`${BASE_ENDPOINT}/${payload.id}/PheDuyet`, payload).catch(error => {
      context.commit(types.APPROVE_THONGBAOKEHOACH_FAILED, {
        error: true,
        message: `${error.response.status} ${error.response.statusText}`
      })
    })
    if (response) {
      if (response.data.error) {
        context.commit(types.APPROVE_THONGBAOKEHOACH_FAILED, response.data)
      } else {
        context.commit(types.APPROVE_THONGBAOKEHOACH_SUCCESS, response.data)
      }
    }
  },
  [types.GET_YKIENPHEDUYET]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYET)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyet`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYET_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYET_FAILED, response.data.message || response.statusText)
    }
  },
  [types.GET_YKIENPHEDUYETGANNHAT]: async (context, id) => {
    context.commit(types.GET_YKIENPHEDUYETGANNHAT)
    const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/${id}/ykienpheduyetgannhat`)
    if (response && response.data && !response.data.error) {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_SUCCESS, response.data)
    } else {
      context.commit(types.GET_YKIENPHEDUYETGANNHAT_FAILED, response.data.message || response.statusText)
    }
  }
}
