export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  editedItem: state => state.editedItem,
  responseResult: state => state.responseResult,
  formTitle: state => state.editedItem.id ? 'Sửa thông báo tô chức hoạt động XTĐT không sử dụng ngân sách nhà nước' : 'Thêm thông báo tô chức hoạt động XTĐT không sử dụng ngân sách nhà nước',
  isSaving: state => state.isSaving,
  isCreateOrUpdateModalOpen: state => state.isCreateOrUpdateModalOpen,
  isCreateModalOpen: state => state.isCreateModalOpen,
  yKienPheDuyet: state => state.yKienPheDuyet,
  yKienPheDuyetGanNhat: state => state.yKienPheDuyetGanNhat,
  isDeleting: state => state.isDeleting,
  deletedItem: state => state.deletedItem,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  detailItem: state => state.detailItem,
  isDetailModalOpen: state => state.isDetailModalOpen,
  isApproving: state => state.isApproving,
  isGetting: state => state.isGetting
}
