export const GET_LIST_TINHTHANHPHO_PAGING = 'GET_LIST_TINHTHANHPHO_PAGING'
export const GET_LIST_TINHTHANHPHO_PAGING_SUCCESS = 'GET_LIST_TINHTHANHPHO_PAGING_SUCCESS'
export const GET_LIST_TINHTHANHPHO_PAGING_FAILED = 'GET_LIST_TINHTHANHPHO_PAGING_FAILED'

export const GET_TINHTHANHPHO = 'GET_TINHTHANHPHO'
export const GET_TINHTHANHPHO_SUCCESS = 'GET_TINHTHANHPHO_SUCCESS'
export const GET_TINHTHANHPHO_FAILED = 'GET_TINHTHANHPHO_FAILED'

export const CREATE_TINHTHANHPHO = 'CREATE_TINHTHANHPHO'
export const CREATE_TINHTHANHPHO_SUCCESS = 'CREATE_TINHTHANHPHO_SUCCESS'
export const CREATE_TINHTHANHPHO_FAILED = 'CREATE_TINHTHANHPHO_FAILED'

export const UPDATE_TINHTHANHPHO = 'UPDATE_TINHTHANHPHO'
export const UPDATE_TINHTHANHPHO_SUCCESS = 'UPDATE_TINHTHANHPHO_SUCCESS'
export const UPDATE_TINHTHANHPHO_FAILED = 'UPDATE_TINHTHANHPHO_FAILED'

export const DELETE_TINHTHANHPHO = 'DELETE_TINHTHANHPHO'
export const DELETE_TINHTHANHPHO_SUCCESS = 'DELETE_TINHTHANHPHO_SUCCESS'
export const DELETE_TINHTHANHPHO_FAILED = 'DELETE_TINHTHANHPHO_FAILED'
