export const GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING = 'GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING'
export const GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_SUCCESS = 'GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_SUCCESS'
export const GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_FAILED = 'GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING_FAILED'

export const GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING = 'GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING'
export const GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_SUCCESS = 'GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_SUCCESS'
export const GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_FAILED = 'GET_LIST_DEXUATCTXTDTQUOCGIA_DIEUCHINH_PAGING_FAILED'

export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL'
export const GET_DEXUATCTXTDTQUOCGIA = 'GET_DEXUATCTXTDTQUOCGIA'
export const GET_DEXUATCTXTDTQUOCGIA_SUCCESS = 'GET_DEXUATCTXTDTQUOCGIA_SUCCESS'
export const GET_DEXUATCTXTDTQUOCGIA_FAILED = 'GET_DEXUATCTXTDTQUOCGIA_FAILED'

export const GET_CTXTDTQGPHULUC = 'GET_CTXTDTQGPHULUC'
export const GET_CTXTDTQGPHULUC_SUCCESS = 'GET_CTXTDTQGPHULUC_SUCCESS'
export const GET_CTXTDTQGPHULUC_FAILED = 'GET_CTXTDTQGPHULUC_FAILED'

export const TOGGLE_CREATE_UPDATE_MODAL = 'TOGGLE_CREATE_UPDATE_MODAL'
export const TOGGLE_CREATE_UPDATE_PHULUC_MODAL = 'TOGGLE_CREATE_UPDATE_PHULUC_MODAL'
export const TOGGLE_UPDATE_PHULUC_MODAL = 'TOGGLE_UPDATE_PHULUC_MODAL'

export const TOGGLE_CREATE_PHULUC_MODAL = 'TOGGLE_CREATE_PHULUC_MODAL'
export const TOGGLE_CREATE_DEAN_MODAL = 'TOGGLE_CREATE_DEAN_MODAL'
export const TOGGLE_DETAIL_DEAN_MODAL = 'TOGGLE_DETAIL_DEAN_MODAL'
export const TOGGLE_UPDATE_DEAN_MODAL = 'TOGGLE_UPDATE_DEAN_MODAL'

export const CREATE_DEXUATCTXTDTQUOCGIA = 'CREATE_DEXUATCTXTDTQUOCGIA'
export const CREATE_DEXUATCTXTDTQUOCGIA_SUCCESS = 'CREATE_DEXUATCTXTDTQUOCGIA_SUCCESS'
export const CREATE_DEXUATCTXTDTQUOCGIA_FAILED = 'CREATE_DEXUATCTXTDTQUOCGIA_FAILED'

export const CREATE_CTXTDTQGPHULUC = 'CREATE_CTXTDTQGPHULUC'
export const CREATE_CTXTDTQGPHULUC_SUCCESS = 'CREATE_CTXTDTQGPHULUC_SUCCESS'
export const CREATE_CTXTDTQGPHULUC_FAILED = 'CREATE_CTXTDTQGPHULUC_FAILED'

export const CREATE_DEANDEXUATCTXTDTQUOCGIA = 'CREATE_DEANDEXUATCTXTDTQUOCGIA'
export const CREATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS = 'CREATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS'
export const CREATE_DEANDEXUATCTXTDTQUOCGIA_FAILED = 'CREATE_DEANDEXUATCTXTDTQUOCGIA_FAILED'

export const UPDATE_DEXUATCTXTDTQUOCGIA = 'UPDATE_DEXUATCTXTDTQUOCGIA'
export const UPDATE_DEXUATCTXTDTQUOCGIA_SUCCESS = 'UPDATE_DEXUATCTXTDTQUOCGIA_SUCCESS'
export const UPDATE_DEXUATCTXTDTQUOCGIA_FAILED = 'UPDATE_DEXUATCTXTDTQUOCGIA_FAILED'

export const DIEUCHINH_DEXUATCTXTDTQUOCGIA = 'DIEUCHINH_DEXUATCTXTDTQUOCGIA'
export const DIEUCHINH_DEXUATCTXTDTQUOCGIA_SUCCESS = 'DIEUCHINH_DEXUATCTXTDTQUOCGIA_SUCCESS'
export const DIEUCHINH_DEXUATCTXTDTQUOCGIA_FAILED = 'DIEUCHINH_DEXUATCTXTDTQUOCGIA_FAILED'

export const UPDATE_CTXTDTQGPHULUC = 'UPDATE_CTXTDTQGPHULUC'
export const UPDATE_CTXTDTQGPHULUC_SUCCESS = 'UPDATE_CTXTDTQGPHULUC_SUCCESS'
export const UPDATE_CTXTDTQGPHULUC_FAILED = 'UPDATE_CTXTDTQGPHULUC_FAILED'

export const UPDATE_DEANDEXUATCTXTDTQUOCGIA = 'UPDATE_DEANDEXUATCTXTDTQUOCGIA'
export const UPDATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS = 'UPDATE_DEANDEXUATCTXTDTQUOCGIA_SUCCESS'
export const UPDATE_DEANDEXUATCTXTDTQUOCGIA_FAILED = 'UPDATE_DEANDEXUATCTXTDTQUOCGIA_FAILED'

export const DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA = 'DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA'
export const DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_SUCCESS = 'DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_SUCCESS'
export const DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_FAILED = 'DIEUCHINH_DEANDEXUATCTXTDTQUOCGIA_FAILED'

export const TOGGLE_DELETE_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CONFIRMATION_MODAL'
export const DELETE_DEXUATCTXTDTQUOCGIA = 'DELETE_DEXUATCTXTDTQUOCGIA'
export const DELETE_DEXUATCTXTDTQUOCGIA_SUCCESS = 'DELETE_DEXUATCTXTDTQUOCGIA_SUCCESS'
export const DELETE_DEXUATCTXTDTQUOCGIA_FAILED = 'DELETE_DEXUATCTXTDTQUOCGIA_FAILED'

export const DELETE_CTXTDTQGPHULUC = 'DELETE_CTXTDTQGPHULUC'
export const DELETE_CTXTDTQGPHULUC_SUCCESS = 'DELETE_CTXTDTQGPHULUC_SUCCESS'
export const DELETE_CTXTDTQGPHULUC_FAILED = 'DELETE_CTXTDTQGPHULUC_FAILED'

export const APPROVE_DEXUATCTXTDTQUOCGIA = 'APPROVE_DEXUATCTXTDTQUOCGIA'
export const APPROVE_DEXUATCTXTDTQUOCGIA_SUCCESS = 'APPROVE_DEXUATCTXTDTQUOCGIA_SUCCESS'
export const APPROVE_DEXUATCTXTDTQUOCGIA_FAILED = 'APPROVE_DEXUATCTXTDTQUOCGIA_FAILED'

export const GET_YKIENPHEDUYET = 'GET_YKIENPHEDUYET'
export const GET_YKIENPHEDUYET_SUCCESS = 'GET_YKIENPHEDUYET_SUCCESS'
export const GET_YKIENPHEDUYET_FAILED = 'GET_YKIENPHEDUYET_FAILED'

export const GET_YKIENPHEDUYETGANNHAT = 'GET_YKIENPHEDUYETGANNHAT'
export const GET_YKIENPHEDUYETGANNHAT_SUCCESS = 'GET_YKIENPHEDUYETGANNHAT_SUCCESS'
export const GET_YKIENPHEDUYETGANNHAT_FAILED = 'GET_YKIENPHEDUYETGANNHAT_FAILED'

export const GET_TONGHOPDEXUAT1111 = 'GET_TONGHOPDEXUAT1111'
export const GET_TONGHOPDEXUAT1111_SUCCESS = 'GET_TONGHOPDEXUAT1111_SUCCESS'
export const GET_TONGHOPDEXUAT1111_FAILED = 'GET_TONGHOPDEXUAT1111_FAILED'

export const GET_TONGHOPDEXUAT1112 = 'GET_TONGHOPDEXUAT1112'
export const GET_TONGHOPDEXUAT1112_SUCCESS = 'GET_TONGHOPDEXUAT1112_SUCCESS'
export const GET_TONGHOPDEXUAT1112_FAILED = 'GET_TONGHOPDEXUAT1112_FAILED'

export const GET_TONGHOPDEXUAT1114 = 'GET_TONGHOPDEXUAT1114'
export const GET_TONGHOPDEXUAT1114_SUCCESS = 'GET_TONGHOPDEXUAT1114_SUCCESS'
export const GET_TONGHOPDEXUAT1114_FAILED = 'GET_TONGHOPDEXUAT1114_FAILED'

export const GET_HISTORY = 'GET_HISTORY'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const GET_HISTORY_FAILED = 'GET_HISTORY_FAILED'

export const GET_HISTORYDETAIL = 'GET_HISTORYDETAIL'
export const GET_HISTORYDETAIL_SUCCESS = 'GET_HISTORYDETAIL_SUCCESS'
export const GET_HISTORYDETAIL_FAILED = 'GET_HISTORYDETAIL_FAILED'

export const GET_HISTORYDEAN = 'GET_HISTORYDEAN'
export const GET_HISTORYDEAN_SUCCESS = 'GET_HISTORYDEAN_SUCCESS'
export const GET_HISTORYDEAN_FAILED = 'GET_HISTORYDEAN_FAILED'

export const GET_HISTORYHANGMUCCONGVIEC = 'GET_HISTORYHANGMUCCONGVIEC'
export const GET_HISTORYHANGMUCCONGVIEC_SUCCESS = 'GET_HISTORYHANGMUCCONGVIEC_SUCCESS'
export const GET_HISTORYHANGMUCCONGVIEC_FAILED = 'GET_HISTORYHANGMUCCONGVIEC_FAILED'

export const GET_HISTORYDUTOANKINHPHI = 'GET_HISTORYDUTOANKINHPHI'
export const GET_HISTORYDUTOANKINHPHI_SUCCESS = 'GET_HISTORYDUTOANKINHPHI_SUCCESS'
export const GET_HISTORYDUTOANKINHPHI_FAILED = 'GET_HISTORYDUTOANKINHPHI_FAILED'
