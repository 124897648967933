export default {
  isFetchingList: state => state.isFetchingList,
  dataSourcePaging: state => state.dataSourcePaging,
  isFetchingListDieuChinh: state => state.isFetchingListDieuChinh,
  dataSourcePagingDieuChinh: state => state.dataSourcePagingDieuChinh,
  editedItem: state => state.editedItem,
  phulucItem: state => state.phulucItem,
  responseResult: state => state.responseResult,
  formTitle: state => state.editedItem.id ? 'Sửa CT XTĐT Quốc gia' : 'Thêm CT XTĐT Quốc gia',
  formPhuLucTitle: state => state.phulucItem.id ? 'Sửa phụ lục CT XTĐT Quốc gia' : 'Thêm phụ lục CT XTĐT Quốc gia',
  isSaving: state => state.isSaving,
  isSaving2: state => state.isSaving2,
  isSavingPhuLuc: state => state.isSavingPhuLuc,
  isSavingPhuLuc2: state => state.isSavingPhuLuc2,
  isSavingDeAn: state => state.isSavingDeAn,
  isSavingDeAn2: state => state.isSavingDeAn2,
  isCreateOrUpdateModalOpen: state => state.isCreateOrUpdateModalOpen,
  isCreateOrUpdatePhuLucModalOpen: state => state.isCreateOrUpdatePhuLucModalOpen,
  isCreatePhuLucModalOpen: state => state.isCreatePhuLucModalOpen,
  isCreateDeAnModalOpen: state => state.isCreateDeAnModalOpen,
  isDetailDeAnModalOpen: state => state.isDetailDeAnModalOpen,
  isUpdateDeAnModalOpen: state => state.isUpdateDeAnModalOpen,
  yKienPheDuyet: state => state.yKienPheDuyet,
  yKienPheDuyetGanNhat: state => state.yKienPheDuyetGanNhat,
  tongHopDeXuat1111: state => state.tongHopDeXuat1111,
  tongHopDeXuat1112: state => state.tongHopDeXuat1112,
  tongHopDeXuat1114: state => state.tongHopDeXuat1114,
  historyData: state => state.historyData,
  historyDetail: state => state.historyDetail,
  historyDeAn: state => state.historyDeAn,
  historyHangMucCongViec: state => state.historyHangMucCongViec,
  historyDuToanKinhPHi: state => state.historyDuToanKinhPHi,
  isDeleting: state => state.isDeleting,
  deletedItem: state => state.deletedItem,
  isDeleteConfirmationModalOpen: state => state.isDeleteConfirmationModalOpen,
  detailItem: state => state.detailItem,
  isDetailModalOpen: state => state.isDetailModalOpen,
  isPhuLucDetailModalOpen: state => state.isPhuLucDetailModalOpen,
  isPhuLucUpdateModalOpen: state => state.isPhuLucUpdateModalOpen,
  isFetchingSingle: state => state.isFetchingSingle,
  isApproving: state => state.isApproving,
  isGetting: state => state.isGetting
}
