import axios from 'axios'
import appConfig from '@/shared/appConfig'
import qs from 'qs'

const state = () => ({
  authData: (localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : {}) || {},
  loginStatus: '',
  isLoading: false
})

const getters = {
  getLoginStatus (state) {
    return state.loginStatus
  },
  getAuthData (state) {
    return state.authData
  },
  isTokenActive (state) {
    if (!state.authData || !state.authData.expiredTime) {
      return false
    }
    return new Date() <= new Date(state.authData.expiredTime)
  },
  isLoading (state) {
    return state.isLoading
  },
  danhMucItems (state) {
    const permissions = state.authData.permissions
    const items = []
    if (permissions.includes('ADMIN') || permissions.includes('DMDONVI_VIEW')) {
      items.push({
        id: 'DmDonVi',
        name: 'Đơn vị',
        to: '/danh-muc/don-vi'
      })
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMLOAIHOATDONG_VIEW')) {
      items.push({
        id: 'DmLoaiHoatDong',
        name: 'Loại hoạt động',
        to: '/danh-muc/loai-hoat-dong'
      })
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMNGANHLINHVUCKEUGOIDAUTU_VIEW')) {
      items.push({
        id: 'DmNganhLinhVucKeuGoiDauTu',
        name: 'Ngành lĩnh vực kêu gọi đầu tư',
        to: '/danh-muc/nganh-linh-vuc-keu-goi-dau-tu'
      })
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMQUOCGIA_VIEW')) {
      items.push({
        id: 'DmQuocGia',
        name: 'Quốc gia',
        to: '/danh-muc/quoc-gia'
      })
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMTINHTHANH_VIEW')) {
      items.push({
        id: 'DmTinhThanhPho',
        name: 'Tỉnh thành phố',
        to: '/danh-muc/tinh-thanh-pho'
      })
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMVUNGKINHTE_VIEW')) {
      items.push({
        id: 'DmVungKinhTe',
        name: 'Vùng kinh tế',
        to: '/danh-muc/vung-kinh-te'
      })
    }
    return items
  },
  viewNguoiDungItem (state) {
    const permissions = state.authData.permissions
    return permissions.includes('ADMIN') || permissions.includes('APPLICATION_USER_VIEW')
  },
  viewNhomQuyenItem (state) {
    const permissions = state.authData.permissions
    return permissions.includes('ADMIN') || permissions.includes('APPLICATION_ROLE_VIEW')
  },
  viewVanBanTBKHTCHDXTHHDTTMDLNGKT (state) {
    const permissions = state.authData.permissions
    return permissions.includes('VanBanTBKHTCHDXTHHDTTMDLNGKT_VIEW')
  },
  viewVanBanTBTCHDXTDTKSDNSNN (state) {
    const permissions = state.authData.permissions
    return permissions.includes('VanBanTBTCHDXTDTKSDNSNN_VIEW')
  },
  viewVanBanPAXTDTTHDDNCCNC (state) {
    const permissions = state.authData.permissions
    return permissions.includes('VanBanPAXTDTTHDDNCCNC_VIEW')
  },
  deXuatCTXTDTBoNganhItems (state) {
    const permissions = state.authData.permissions
    const items = []
    if (permissions.includes('DEXUATCTXTDT_BONGANH_VIEW')) {
      items.push({
        id: 'DSDeXuatBoNganh',
        name: 'Danh sách đề xuất',
        to: '/de-xuat-ct-xtdt'
      })
    }
    if (permissions.includes('DEXUATCTXTDT_BONGANH_REPORT')) {
      items.push({
        id: 'BCDeXuatBoNganh',
        name: 'Báo cáo đề xuất',
        to: '/de-xuat-ct-xtdt/bao-cao'
      })
    }
    return items
  },
  deXuatCTXTDTDiaPhuongItems (state) {
    const permissions = state.authData.permissions
    const items = []
    if (permissions.includes('DEXUATCTXTDT_DIAPHUONG_VIEW')) {
      items.push({
        id: 'DSDeXuatDiaPhuong',
        name: 'Danh sách đề xuất',
        to: '/de-xuat-ct-xtdt-dia-phuong'
      })
    }
    if (permissions.includes('DEXUATCTXTDT_DIAPHUONG_REPORT')) {
      items.push({
        id: 'BCDeXuatDiaPhuong',
        name: 'Báo cáo đề xuất',
        to: '/de-xuat-ct-xtdt-dia-phuong/bao-cao'
      })
    }
    return items
  },
  deXuatCTXTDTQuocGiaItems (state) {
    const permissions = state.authData.permissions
    const items = []
    if (permissions.includes('DEXUATCTXTDT_QUOCGIA_VIEW')) {
      items.push({
        id: 'DSDeXuatQuocGia',
        name: 'Danh sách đề xuất',
        to: '/de-xuat-ct-xtdt-qg'
      })
    }
    if (permissions.includes('DEXUATCTXTDT_QUOCGIA_REPORT')) {
      items.push({
        id: 'BCDeXuatQuocGia',
        name: 'Báo cáo đề xuất',
        to: '/de-xuat-ct-xtdt-qg/bao-cao'
      })
    }
    return items
  },
  permissionsDmDonVi (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMDONVI_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMDONVI_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMDONVI_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsDmLoaiHoatDong (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMLOAIHOATDONG_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMLOAIHOATDONG_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMLOAIHOATDONG_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsDmNganhLinhVucKeuGoiDauTu (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMNGANHLINHVUCKEUGOIDAUTU_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMNGANHLINHVUCKEUGOIDAUTU_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMNGANHLINHVUCKEUGOIDAUTU_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsDmQuocGia (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMQUOCGIA_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMQUOCGIA_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMQUOCGIA_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsDmTinhThanh (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMTINHTHANH_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMTINHTHANH_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMTINHTHANH_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsDmVungKinhTe (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMVUNGKINHTE_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMVUNGKINHTE_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('DMVUNGKINHTE_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsApplicationUser (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('APPLICATION_USER_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('APPLICATION_USER_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('APPLICATION_USER_DELETE')) {
      per.isDelete = true
    }
    return per
  },
  permissionsApplicationRole (state) {
    const permissions = state.authData.permissions
    const per = {
      isAdd: false,
      isUpdate: false,
      isDelete: false
    }
    if (permissions.includes('ADMIN') || permissions.includes('APPLICATION_ROLE_ADD')) {
      per.isAdd = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('APPLICATION_ROLE_UPDATE')) {
      per.isUpdate = true
    }
    if (permissions.includes('ADMIN') || permissions.includes('APPLICATION_ROLE_DELETE')) {
      per.isDelete = true
    }
    return per
  }
}

const actions = {
  async login (context, {
    username,
    password
  }) {
    context.commit('setIsLoading', true)
    const payload = {
      grant_type: 'password',
      username,
      password,
      client_id: appConfig.CLIENT_ID,
      client_secret: appConfig.CLIENT_SECRET
    }
    const response = await axios
      .post(`${appConfig.BASE_HOST}/token`, qs.stringify(payload))
      .catch((err) => {
        context.commit('setIsLoading', false)
        console.log(err)
      })
    if (response && response.data) {
      context.commit('saveTokenData', response.data)
      context.commit('setLoginStatus', 'success')
    } else {
      context.commit('setLoginStatus', 'failed')
    }
    context.commit('setIsLoading', false)
  },
  async saveTokensToStorage (context, data) {
    context.commit('saveTokenData', data)
  },
  async logout (context) {
    context.commit('saveTokenData', {})
  }
}

const mutations = {
  saveTokenData (state, data) {
    let newTokenData
    if (data) {
      newTokenData = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiredTime: data['.expires'],
        userName: data.userName,
        fullName: data.fullName,
        email: data.email,
        permissions: data.permissions
      }
      state.authData = newTokenData
    } else {
      newTokenData = {}
    }
    localStorage.setItem('authData', JSON.stringify(newTokenData))
  },
  setLoginStatus (state, value) {
    state.loginStatus = value
  },
  setIsLoading (state, value) {
    state.isLoading = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
