export const GET_LIST_THONGBAOKEHOACH_PAGING = 'GET_LIST_THONGBAOKEHOACH_PAGING'
export const GET_LIST_THONGBAOKEHOACH_PAGING_SUCCESS = 'GET_LIST_THONGBAOKEHOACH_PAGING_SUCCESS'
export const GET_LIST_THONGBAOKEHOACH_PAGING_FAILED = 'GET_LIST_THONGBAOKEHOACH_PAGING_FAILED'

export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL'
export const GET_THONGBAOKEHOACH = 'GET_THONGBAOKEHOACH'
export const GET_THONGBAOKEHOACH_SUCCESS = 'GET_THONGBAOKEHOACH_SUCCESS'
export const GET_THONGBAOKEHOACH_FAILED = 'GET_THONGBAOKEHOACH_FAILED'

export const TOGGLE_CREATE_UPDATE_MODAL = 'TOGGLE_CREATE_UPDATE_MODAL'

export const CREATE_THONGBAOKEHOACH = 'CREATE_THONGBAOKEHOACH'
export const CREATE_THONGBAOKEHOACH_SUCCESS = 'CREATE_THONGBAOKEHOACH_SUCCESS'
export const CREATE_THONGBAOKEHOACH_FAILED = 'CREATE_THONGBAOKEHOACH_FAILED'

export const UPDATE_THONGBAOKEHOACH = 'UPDATE_THONGBAOKEHOACH'
export const UPDATE_THONGBAOKEHOACH_SUCCESS = 'UPDATE_THONGBAOKEHOACH_SUCCESS'
export const UPDATE_THONGBAOKEHOACH_FAILED = 'UPDATE_THONGBAOKEHOACH_FAILED'

export const TOGGLE_DELETE_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CONFIRMATION_MODAL'
export const DELETE_THONGBAOKEHOACH = 'DELETE_THONGBAOKEHOACH'
export const DELETE_THONGBAOKEHOACH_SUCCESS = 'DELETE_THONGBAOKEHOACH_SUCCESS'
export const DELETE_THONGBAOKEHOACH_FAILED = 'DELETE_THONGBAOKEHOACH_FAILED'

export const APPROVE_THONGBAOKEHOACH = 'APPROVE_THONGBAOKEHOACH'
export const APPROVE_THONGBAOKEHOACH_SUCCESS = 'APPROVE_THONGBAOKEHOACH_SUCCESS'
export const APPROVE_THONGBAOKEHOACH_FAILED = 'APPROVE_THONGBAOKEHOACH_FAILED'

export const GET_YKIENPHEDUYET = 'GET_YKIENPHEDUYET'
export const GET_YKIENPHEDUYET_SUCCESS = 'GET_YKIENPHEDUYET_SUCCESS'
export const GET_YKIENPHEDUYET_FAILED = 'GET_YKIENPHEDUYET_FAILED'

export const GET_YKIENPHEDUYETGANNHAT = 'GET_YKIENPHEDUYETGANNHAT'
export const GET_YKIENPHEDUYETGANNHAT_SUCCESS = 'GET_YKIENPHEDUYETGANNHAT_SUCCESS'
export const GET_YKIENPHEDUYETGANNHAT_FAILED = 'GET_YKIENPHEDUYETGANNHAT_FAILED'
