export const logo = [
  '376 178',
  `
  <title>coreui vue</title>
  <g xmlns="http://www.w3.org/2000/svg" class="signet" style="fill: #fff">
    <path class="cls-2" d="M128,56.1244,89,33.6077a12,12,0,0,0-12,0L38,56.1243a12.0339,12.0339,0,0,0-6,10.3924V111.55a12.0333,12.0333,0,0,0,6,10.3923L77,144.459a12,12,0,0,0,12,0l39-22.5167a12.0333,12.0333,0,0,0,6-10.3923V66.5167A12.0336,12.0336,0,0,0,128,56.1244ZM126,111.55a4,4,0,0,1-2,3.4641L85,137.5308a4,4,0,0,1-4,0L42,115.0141a4,4,0,0,1-2-3.4641V66.5167a4,4,0,0,1,2-3.4641L81,40.5359a4,4,0,0,1,4,0l39,22.5167a4,4,0,0,1,2,3.4641Z"/>
    <path class="cls-2" d="M106.0216,102.0713h-2.866a3.9993,3.9993,0,0,0-1.9246.4935L83.95,112.05,64,100.5315V77.554L83.95,66.0354,101.239,75.49a4.0007,4.0007,0,0,0,1.9192.4905h2.8633a2,2,0,0,0,2-2V71.2691a2,2,0,0,0-1.04-1.7547L87.793,59.0188a8.0391,8.0391,0,0,0-7.8428.09L60,70.6262A8.0245,8.0245,0,0,0,56,77.5549v22.976a8,8,0,0,0,4,6.9283l19.95,11.5186a8.0429,8.0429,0,0,0,7.8433.0879l19.19-10.5312a2,2,0,0,0,1.0377-1.7533v-2.71A2,2,0,0,0,106.0216,102.0713Z"/>
  </g>
  <g xmlns="http://www.w3.org/2000/svg" class="text" style="fill: #fff">
    <path class="cls-2" d="M200.7446,65.4285a15.0166,15.0166,0,0,0-15,14.9995V97.7054a15,15,0,0,0,30,0V80.428A15.0166,15.0166,0,0,0,200.7446,65.4285Zm7,32.2769a7,7,0,0,1-14,0V80.428a7,7,0,0,1,14,0Z"/>
    <path class="cls-2" d="M164.0789,73.4878a7.01,7.01,0,0,1,7.868,6.0752.9894.9894,0,0,0,.9843.865h6.0305a1.0108,1.0108,0,0,0,.9987-1.0971,15.0182,15.0182,0,0,0-15.7163-13.8837A15.2881,15.2881,0,0,0,150,80.8635V97.27a15.2881,15.2881,0,0,0,14.2441,15.4163A15.0183,15.0183,0,0,0,179.96,98.8025a1.0107,1.0107,0,0,0-.9987-1.0971h-6.0305a.9894.9894,0,0,0-.9843.865,7.01,7.01,0,0,1-7.868,6.0757A7.1643,7.1643,0,0,1,158,97.4612V80.6724A7.1639,7.1639,0,0,1,164.0789,73.4878Z"/>
    <path class="cls-2" d="M246.9221,92.9944a12.1584,12.1584,0,0,0,7.1843-11.0771V78.2161A12.1494,12.1494,0,0,0,241.957,66.0667H225a1,1,0,0,0-1,1v44a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1v-17h6.6216l7.9153,17.4138a1,1,0,0,0,.91.5862h6.591a1,1,0,0,0,.91-1.4138Zm-.8157-11.0771a4.1538,4.1538,0,0,1-4.1489,4.1494h-9.8511v-12h9.8511a4.1538,4.1538,0,0,1,4.1489,4.1494Z"/>
    <path class="cls-2" d="M289,66.0667H263a1,1,0,0,0-1,1v44a1,1,0,0,0,1,1h26a1,1,0,0,0,1-1v-6a1,1,0,0,0-1-1H270v-12h13a1,1,0,0,0,1-1v-6a1,1,0,0,0-1-1H270v-10h19a1,1,0,0,0,1-1v-6A1,1,0,0,0,289,66.0667Z"/>
    <path class="cls-2" d="M327,66.0667h-6a1,1,0,0,0-1,1V89.7142a7.0066,7.0066,0,1,1-14,0V67.0667a1,1,0,0,0-1-1h-6a1,1,0,0,0-1,1V89.7142a15.0031,15.0031,0,1,0,30,0V67.0667A1,1,0,0,0,327,66.0667Z"/>
    <rect class="cls-2" x="336" y="66.0667" width="8" height="38" rx="1"/>
  </g>
`
]
