import { actions } from '@/store/modules/loai-hoat-dong/actions'
import mutations from '@/store/modules/loai-hoat-dong/mutations'
import getters from '@/store/modules/loai-hoat-dong/getters'

const state = {
  isFetchingList: false,
  isFetchingSingle: false,
  dataSourcePaging: {},
  isCreateOrUpdateModalOpen: false,
  isSaving: false,
  editedItem: {},
  isDeleting: false,
  deletedItem: {},
  isDeleteConfirmationModalOpen: false,
  responseResult: {},
  detailItem: {},
  isDetailModalOpen: false
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
