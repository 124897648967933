export const GET_LIST_DONVI_PAGING = 'GET_LIST_DONVI_PAGING'
export const GET_LIST_DONVI_PAGING_SUCCESS = 'GET_LIST_DONVI_PAGING_SUCCESS'
export const GET_LIST_DONVI_PAGING_FAILED = 'GET_LIST_DONVI_PAGING_FAILED'

export const GET_DONVI = 'GET_DONVI'
export const GET_DONVI_SUCCESS = 'GET_DONVI_SUCCESS'
export const GET_DONVI_FAILED = 'GET_DONVI_FAILED'

export const CREATE_DONVI = 'CREATE_DONVI'
export const CREATE_DONVI_SUCCESS = 'CREATE_DONVI_SUCCESS'
export const CREATE_DONVI_FAILED = 'CREATE_DONVI_FAILED'

export const UPDATE_DONVI = 'UPDATE_DONVI'
export const UPDATE_DONVI_SUCCESS = 'UPDATE_DONVI_SUCCESS'
export const UPDATE_DONVI_FAILED = 'UPDATE_DONVI_FAILED'

export const DELETE_DONVI = 'DELETE_DONVI'
export const DELETE_DONVI_SUCCESS = 'DELETE_DONVI_SUCCESS'
export const DELETE_DONVI_FAILED = 'DELETE_DONVI_FAILED'
