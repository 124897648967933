<template>
  <div class="c-app">
    <AppSidebar/>
    <CWrapper>
      <AppHeader/>
      <div class="c-body">
        <main class="c-main">
<!--          <ThongBaoToChucPrint/>-->
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
<!--      <AppFooter/>-->
    </CWrapper>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppSidebar from '@/components/AppSidebar'
// import AppFooter from '@/components/AppFooter'
// import ThongBaoToChucPrint from '@/components/VanBanTBTCHDXTDTKSDNSNN/ThongBaoToChucPrint'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar
    // AppFooter
    // ThongBaoToChucPrint
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
